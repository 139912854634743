import React from 'react'
import ToolComponents from './ToolsComponent'
import { ToolConfig } from './ToolConfig'


const VariableRenamer = () => {
    return (
        <ToolComponents toolConfig={ToolConfig.VariableRenamer}/>
    )
}

export default VariableRenamer
