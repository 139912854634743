import React from 'react'
import ToolComponents from './ToolsComponent'
import { ToolConfig } from './ToolConfig'


const CodeLinter = () => {
    return (
        <ToolComponents toolConfig={ToolConfig.CodeLinter}/>
    )
}

export default CodeLinter
