import React from 'react'
import ToolComponents from './ToolsComponent'
import { ToolConfig } from './ToolConfig'


const TestGenerator = () => {
    return (
        <ToolComponents toolConfig={ToolConfig.TestGenerator}/>
    )
}

export default TestGenerator
