import React from 'react'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { a11yDark } from 'react-syntax-highlighter/dist/cjs/styles/prism'
import { note } from '../mocks/dataIcon';

const CodeCopyBtn = ({ children }) => {
    const [copyOk, setCopyOk] = React.useState(false);

    const handleClick = (e) => {
        navigator.clipboard.writeText(children[0].props.children[0]);
        setCopyOk(true);
    }

    return (
        <div className=" absolute left-[10px] top-1 rounded-md cursor-pointer text-xl duration-300 ease-in-out " onClick={handleClick} >
            <button className=' flex items-center text-des font-main font-semibold hover:bg-bg_close p-1 duration-200 ease-linear'>
                <span className='text-sm text-des '>{note}</span>
                <span className='text-sm text-des '>Copy</span>
            </button>
        </div>
    )
}


const ResponsiveGenerate = (props) => {
    const { messages, textConversation, resultsDataConversation, index, title } = props;
    const Pre = ({ children }) => <pre className="relative shadow-md !mb-[3rem] border border-des rounded-sm ">
        <div className='border-b border-des h-9 '>
            <CodeCopyBtn>{children}</CodeCopyBtn>
        </div>
        {children}
    </pre>
    let genText = index === 0 ? 'Response will gennerate here!' : '...'
    return (
        <>
            <div className='text-text text-sm py-6'>
                {index !== 0 &&
                    <div className='px-9 py-3 bg-bg_close flex items-center '>
                        <p className='h-full font-main'>
                            {title}
                        </p>
                    </div>}
                
                {messages ?
                    <>
                        <div className={` px-9 pt-9  text-sm  `}>
                            <ReactMarkdown
                                className='post-markdown line-break '
                                linkTarget='_blank'
                                rehypePlugins={[rehypeRaw]}
                                remarkPlugins={[remarkGfm]}
                                components={{
                                    pre: Pre,
                                    code({ node, inline, className = "blog-code", children, ...props }) {
                                        const match = /language-(\w+)/.exec(className || '')
                                        return !inline && match ? (
                                            <SyntaxHighlighter
                                                style={{ ...a11yDark }}
                                                customStyle={{
                                                    backgroundColor: "transparent",
                                                    opacity: "1",
                                                    fontSize: '14px'
                                                }}
                                                language={match[1]}
                                                PreTag="div"
                                                {...props}
                                            >
                                                {String(children)}
                                            </SyntaxHighlighter>
                                        ) : (
                                            <code className={className} {...props}>
                                                {children}
                                            </code>
                                        )
                                    }
                                }}
                            >
                                {messages}
                            </ReactMarkdown>
                        </div>
                        {resultsDataConversation ?
                            <div className='bg-bg_close w-full text-text font-main px-9 py-3'>{textConversation ? textConversation : ''}</div>
                            :
                            <></>
                        }
                        {resultsDataConversation ?
                            <div className=' p-9  overflow-auto lg:overflow-y-auto text-sm '>
                                <ReactMarkdown
                                    className='post-markdown line-break'
                                    linkTarget='_blank'
                                    rehypePlugins={[rehypeRaw]}
                                    remarkPlugins={[remarkGfm]}
                                    components={{
                                        pre: Pre,
                                        code({ node, inline, className = "blog-code", children, ...props }) {
                                            const match = /language-(\w+)/.exec(className || '')
                                            return !inline && match ? (
                                                <SyntaxHighlighter
                                                    style={{ ...a11yDark }}
                                                    customStyle={{
                                                        backgroundColor: "transparent",
                                                        opacity: "1",
                                                        fontSize: '14px',


                                                    }}
                                                    language={match[1]}
                                                    PreTag="div"
                                                    {...props}
                                                >
                                                    {String(children)}
                                                </SyntaxHighlighter>
                                            ) : (
                                                <code className={className} {...props}>
                                                    {children}
                                                </code>
                                            )
                                        }
                                    }}
                                >
                                    {resultsDataConversation}
                                </ReactMarkdown>
                            </div>

                            : <></>}
                    </>
                    :
                    <div className='text-sm text-des px-9 '>
                        {genText}
                    </div>
                }

            </div>
        </>
    )
}

export default ResponsiveGenerate