import React, { useRef, useEffect, useState } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";

import debug1 from '../assets/images/debug1.webp';
import debug2 from '../assets/images/debug2.webp';
import debug3 from '../assets/images/debug3.webp';
import regex1 from '../assets/images/regex1.webp';
import regex2 from '../assets/images/regex2.webp';
import regex3 from '../assets/images/regex3.webp';
import snake1 from '../assets/images/snake1.webp';
import snake2 from '../assets/images/snake2.webp';
import snake3 from '../assets/images/snake3.webp';


const defaultSettings = {
    dots: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 3,
    arrows: false,
    // autoplay: true,
    speed: 500,
    // autoplaySpeed: 7500,
    cssEase: "linear",
    
};

const settingsHeader = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    // autoplay: true,
    speed: 100,
    autoplaySpeed: 7000,
    cssEase: "linear",
};


const SliderImage = () => {
    const sliderRef = useRef(null)
    const sliderRefHeader = useRef(null)
    const [progress, setProgress] = React.useState(0);
    const [settings, setSettings] = useState(defaultSettings)



    useEffect(() => {
        const timer = setInterval(() => {
            setProgress((oldProgress) => {
                if (oldProgress === 100) {
                    sliderRef.current.slickNext()
                    sliderRefHeader.current.slickNext()
                    return oldProgress = 0;
                }
                // const diff += 1 ;
                window.addEventListener("resize", handleResize)
                handleResize()
                return Math.min(oldProgress + 1, 100);
            });
        }, 150);

        return () => {
            clearInterval(timer);
        };
    }, []);



    const handleClickRight = () => {
        setProgress(0)
        sliderRef.current.slickNext()
        sliderRefHeader.current.slickNext()
    }

    const handleClickLeft = () => {
        setProgress(0)
        sliderRef.current.slickPrev()
        sliderRefHeader.current.slickPrev()
    }


    const handleResize = () => {
        if (window.innerWidth < 992) {
            setSettings({
                ...settings,
                vertical: true,
                speed: 10
            })
        } else {
            setSettings({
                ...settings,
                vertical: false
            })
        }
    }



    return (
        <div className='relative lg:w-[400px] xs:w-full mx-auto'>
            <div className='flex items-center justify-between xs:w-[90%] xs:mx-auto'>
                <div className='w-[92.5%] clg:w-[80%] xs:w-[75%]'>
                    <Slider ref={sliderRefHeader} className='' {...settingsHeader}>
                        <div className=' text-text  font-bold '>
                            <p className='font-main text-2xl clg:text-base ' >“Debug this TypeScript error”</p>
                        </div>
                        <div className='text-white font-bold  '>
                            <p className='font-main text-2xl  clg:text-base '>“Generate a regex pattern for email addresses”</p>
                        </div>
                        <div className='text-white font-bold xs:w-[75%] '>
                            <p className='font-main text-2xl  clg:text-base '>“Code a Snake game in Python”</p>
                        </div>
                    </Slider>
                </div>
                <div className=' flex justify-end  clg:w-[75%]'>
                    <button onClick={handleClickLeft}
                        className='ml-2 w-8 h-8 bg-gray_body flex items-center justify-center rounded-sm cursor-pointer'>
                        <svg className=" text-grayText h-6 w-6 " width="24" height="24" viewBox="0 0 24 24"
                            strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round"
                            strokeLinejoin="round">  <path stroke="none" d="M0 0h24v24H0z" />
                            <polyline points="15 6 9 12 15 18" />
                        </svg>
                    </button>
                    <button onClick={handleClickRight}
                        className='ml-2 w-8 h-8 bg-gray_body flex items-center justify-center rounded-sm cursor-pointer'>
                        <svg className="h-6 w-6 text-grayText " viewBox="0 0 24 24" fill="none"
                            stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                            <polyline points="9 18 15 12 9 6" />
                        </svg>
                    </button>
                </div>

            </div>


            {/* <div className='loading clg:mt-0 clg:mb-5 mb-8 mt-3' ></div> */}
            <div className='clg:mt-0 clg:mb-5 mb-8 mt-3 overflow-hidden xs:w-[92%] xs:mx-auto'>

                <Box>
                    <LinearProgress
                        sx={{

                            backgroundColor: `black`,
                            "& .MuiLinearProgress-bar": {
                                backgroundColor: `white`
                            }
                        }} variant="determinate" value={progress} />
                </Box>
            </div>

            <Slider ref={sliderRef} className='' {...settings}>
                <div className='xs:mx-2 mx-1 flex justify-center' >
                    <img src={debug1} alt="" className='border-2 xs:mx-auto border-grayText rounded-lg overflow-hidden w-[95%] xs:!w-[90%]' />
                </div>
                <div className='xs:mx-2 mx-1 flex justify-center' >
                    <img src={debug2} alt="" className='border-2 xs:mx-auto border-grayText rounded-lg overflow-hidden w-[95%] xs:!w-[90%]' />
                </div>

                <div className='xs:mx-2 mx-1 flex justify-center' >
                    <img src={debug3} alt="" className='border-2 xs:mx-auto border-grayText rounded-lg overflow-hidden w-[95%] xs:!w-[90%]' />
                </div>

                <div className='xs:mx-2 mx-1 flex justify-center' >
                    <img src={regex1} alt="" className='border-2 xs:mx-auto border-grayText rounded-lg overflow-hidden w-[95%] xs:!w-[90%]' />
                </div>

                <div className='xs:mx-2 mx-1 flex justify-center' >
                    <img src={regex2} alt="" className='border-2 xs:mx-auto border-grayText rounded-lg overflow-hidden !w-[95%] xs:!w-[90%]' />
                </div>

                <div className='xs:mx-2 mx-1 flex justify-center' >
                    <img src={regex3} alt="" className='border-2 xs:mx-auto border-grayText rounded-lg overflow-hidden !w-[95%] xs:!w-[90%]' />
                </div>

                <div className='xs:mx-2 mx-1 flex justify-center' >
                    <img src={snake1} alt="" className='border-2 xs:mx-auto border-grayText rounded-lg overflow-hidden !w-[95%] xs:!w-[90%]' />
                </div>

                <div className='xs:mx-2 mx-1 flex justify-center' >
                    <img src={snake2} alt="" className='border-2 xs:mx-auto border-grayText rounded-lg overflow-hidden !w-[95%] xs:!w-[90%]' />
                </div>

                <div className='xs:mx-2 mx-1 flex justify-center' >
                    <img src={snake3} alt="" className='border-2  xs:mx-auto border-grayText rounded-lg overflow-hidden !w-[95%] xs:!w-[90%]' />
                </div>

            </Slider>


        </div>
    )
}

export default SliderImage