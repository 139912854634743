import React, { useRef, useContext, useEffect } from 'react'
import { Outlet, } from 'react-router-dom';
import SideBar from '../components/SideBar/SideBar';
import HeaderLogin from '../components/Header/HeaderLogin';
import UpgradePlan from '../components/UpgradePlan';
import AccountSetting from '../components/AccountSetting.jsx';
import PaymentDetial from './../components/PaymentDetial';
import MainContext from '../pages/MainContext';
import LogginForm from '../components/LogginForm';



const MainLayout = ({ ChildComponent }) => {
  const { openAccount, statusUp, setStatusUp, payment, setPayment, setOpenAccount, formLogin } = useContext(MainContext)
  const planRef = useRef(null);
  const paymentRef = useRef(null);
  const accountRef = useRef(null);


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (planRef.current && !planRef.current.contains(event.target) && !payment) {
        setStatusUp(false)
      }
      if (paymentRef.current && !paymentRef.current.contains(event.target)) {
        setPayment(false)
      }
      if (accountRef.current && !accountRef.current.contains(event.target)) {
        setOpenAccount(false)
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [openAccount]);


  return (
    <div className='flex bg-bg_black relative min-h-screen overflow-hidden'>
      <div className='w-[288px] h-full'>
        <SideBar />
      </div>
      <div className='w-[calc(100vw-288px)]'>
        <div className='absolute top-0 left-[288px] right-0  '>
          <HeaderLogin />
        </div>
        <div className='mt-[56px] '>
          {ChildComponent ? ChildComponent : <Outlet />}
        </div>
      </div>
      {statusUp ?
        <>
          <div
            className='absolute w-full min-h-screen bg-bg_black_blur top-0 right-0 left-0 z-50 flex items-center justify-center'
          >
            <div>
              <div ref={planRef}>
                <UpgradePlan /></div>
            </div>
          </div>
        </>
        :
        <></>}
      {payment ?
        <>
          <div className='absolute w-full min-h-screen bg-bg_black_blur top-0 right-0 left-0 z-50 flex items-center justify-center'>
            <div>
              <div ref={paymentRef}>
                <PaymentDetial /></div>
            </div>
          </div>
        </>
        :
        <></>}
      {openAccount ?
        <>
          <div className='absolute w-full min-h-screen bg-bg_black_blur top-0 right-0 left-0 z-20 flex items-center justify-center'>
            <div ref={accountRef}>
              <AccountSetting />
            </div>
          </div>
        </>
        :
        <></>
      }
      {formLogin ?
        <>
          <div className='w-full h-full absolute flex items-center justify-center bg-bg_black_blur'>
            <LogginForm />
          </div>
        </> :
        <>
        </>}
    </div>
  )
}

export default MainLayout