import React, { createContext, useState, useEffect } from 'react';
import { InfoUser } from '../services/AuthService';
import { getAccessToken, setToken } from '../services/helper/TokenHelper';

const MainContext = createContext()

export const MainProvider = ({ children }) => {
    const [dataInfo, setDataInfo] = useState(null);
    const [statusUp, setStatusUp] = useState(false);
    const [payment, setPayment] = useState(false);
    const [formLogin, setFormLogin] = useState(false);
    const [openAccount, setOpenAccount] = useState(false);
    const [indexPayment, setIndexPayment] = useState(0);
    const [changeContent, setChangeContent] = useState('');
    const [dataLogin, setDataLogin] = useState(null)
    const [statusQuery, setStatusQuery] = useState(false);
    const [statusRun, setStatusRun] = useState(true);
    const [queryString, setQueryString] = useState(true);
    const [val, setVal] = useState("");
    const [isOpenMenu, setIsOpenMenu] = useState(false);
    const [id, setId] = useState('id')

    const handleClickIconMenu = () => {

        setIsOpenMenu(!isOpenMenu)
    }

    const getDataInfo = async () => {
        try {
            if (getAccessToken()) {
                const res = await InfoUser()
                setDataInfo(res?.data)
                setToken('email', res.data?.email)
                setToken('name', res.data?.lastName || 'No Name')
            }
        } catch (error) {
        }

    }
    const handleClickClose = () => {
        setOpenAccount(false);
        setFormLogin(false);
        setStatusQuery(false);
        document.getElementById('body').classList.remove('activeBody')

    }

    const handleClickUp = () => {
        if (checkLogin()) {
            setStatusUp(!statusUp);
            setOpenAccount(false)
        }
    }

    const handleClickAccount = () => {
        setOpenAccount(!openAccount)
    }

    const handleClickQuery = (queryString) => {
        setStatusQuery(!statusQuery)
        setQueryString(queryString)
    }
    // Click show form login
    const hanldeClickShowFormLogin = () => {
        setFormLogin(!formLogin);
        document.getElementById('body').classList.add('activeBody')
    }


    useEffect(() => {
        getDataInfo()
    }, [statusRun])

    const checkLogin = () => {
        if (!getAccessToken()) {
            setFormLogin(true)
            return false
        }
        return true
    }

    return (
        <MainContext.Provider value={{
            queryString,
            dataInfo, openAccount, statusUp,
            setStatusUp, setOpenAccount, payment, setPayment, setChangeContent,
            indexPayment, setIndexPayment, statusQuery, setStatusQuery, getDataInfo,
            handleClickClose, handleClickUp, handleClickAccount, handleClickQuery, statusRun, setStatusRun,
            val, setVal, hanldeClickShowFormLogin, formLogin, setDataLogin, checkLogin, setFormLogin, setDataInfo,
            isOpenMenu,handleClickIconMenu,id
        }}>
            {children}
        </MainContext.Provider>
    )
}

export default MainContext