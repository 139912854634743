export const dataCollapse = [
    {
        title:'What is codefy.ai?',
        des:'codefy.ai is your own toolbox of useful coding widgets to help you speed up your workflow. Write, explain, debug, compare, and translate code with 15+ helpful, customized tools. Use codefy.ai to learn a new coding topic, build your next app, and more!',
        isOpen: false
    },
    {
        title:'What is the current set of tools?',
        des:'The current set of 20 tools includes Code Writer, Code Explainer, Code Changer, Code Debugger, Regex Generator, Code Translator, Code Optimizer, Code Comparer, Topic Explainer, Code Linter, Code Suggester, Syntax Checker, Code Learner, Code Refactoring, Variable Renamer, Database Query, Code Commenter, Code Documenter, Test Generator, and JSON Generator.',
        isOpen: false
    },
    {
        title:'Is the basic plan enough for me?',
        des:'The basic plan allows you to use 500k tokens per month, which equates to approximately 100k lines of code processed per month. It also allows you to input up to 2,000 tokens at one time. If you believe you may go over any of these limits, the pro plan may be best for you.',
        isOpen: false
    },
    {
        title:'Can I cancel my subscription any time?',
        des:'Yes! You can easily cancel your subscription at any time. For the cost of a couple coffees, there not much risk in seeing how codefy.ai can speed up your workflow.',
        isOpen: false
    },
    {
        title:'Is it safe to use?',
        des:'Yes! codefy.ai securely stores all of your queries and account information. We never sell or share your data.',
        isOpen: false
    },
    {
        title:'Are my queries private?',
        des:'Queries are by default private, although they can be shared at any time with a custom link',
        isOpen: false,
        status:true
    },
    
]