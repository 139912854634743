import {ButtonRed} from "../components/ButtonRed";
export const icon = <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"><path d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10 10-4.5 10-10S17.5 2 12 2Zm4.2 8.3-4.8 4.8c-.4.4-1 .4-1.4 0l-2.2-2.2c-.4-.4-.4-1 0-1.4.4-.4 1-.4 1.4 0l1.5 1.5 4.1-4.1c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4Z"></path></svg>



export const DataSricing = [
    {
        header: 'Starter',
        text: 'Free',
        icon: icon,
        line1: <p className="text-lg font-light text-text font-iter"><span className="font-bold">10K</span> tokens/month</p>,
        line2: <p className="text-lg font-light text-text"><span className="font-bold">~2k</span> lines of code processed/ month</p>,
        line3: <p className="text-lg font-light text-text"><span className="font-bold">1,000 </span> token input limit</p>,
        line4: <p className="text-lg font-light text-text"> Most features</p>,
        button: <ButtonRed path='/' title='Get started' />
    },
    {
        topName: 'Most Popular!',
        header: 'Basic',
        text: <span className="text-5xl">$9<span className="text-2xl text-des">/mo</span></span>,
        text_span: <span className="text-des">(2 mornings of coffee)</span>,
        icon: icon,
        line1: <p className="text-lg font-light text-text"><span className="font-bold">500k </span> tokens/month</p>,
        line2: <p className="text-lg font-light text-text"><span className="font-bold">~100k</span> lines of code processed/ month</p>,
        line3: <p className="text-lg font-light text-text"><span className="font-bold">4,000 </span> token input limit</p>,
        line4: <p className="text-lg font-light text-text"><span className="font-bold">Standard </span> access to new features</p>,
        button: <ButtonRed path='/' title='Get started' />
    },
    {
        header: <div className="flex items-center">Pro <svg className="ml-2" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"><path fill="#F30" d="M21.71 11.29 19.36 9V5.64a1 1 0 0 0-1-1h-3.31l-2.34-2.35a.999.999 0 0 0-1.42 0L9 4.64H5.64a1 1 0 0 0-1 1V9l-2.35 2.29a1 1 0 0 0 0 1.42l2.35 2.34v3.31a1 1 0 0 0 1 1H9l2.34 2.35a1.001 1.001 0 0 0 1.42 0l2.34-2.35h3.31a1 1 0 0 0 1-1v-3.31l2.35-2.34a1 1 0 0 0-.05-1.42Zm-4.05 2.64a1.002 1.002 0 0 0-.3.71v2.72h-2.72a.998.998 0 0 0-.71.3L12 19.59l-1.93-1.93a1 1 0 0 0-.71-.3H6.64v-2.72a1 1 0 0 0-.3-.71L4.41 12l1.93-1.93a1 1 0 0 0 .3-.71V6.64h2.72a1 1 0 0 0 .71-.3L12 4.41l1.93 1.93a1 1 0 0 0 .71.3h2.72v2.72a1.001 1.001 0 0 0 .3.71L19.59 12l-1.93 1.93Z"></path><path fill="#F30" fill-rule="evenodd" d="m6 15-3-3 3-3V6h3l3-3 3 3h3v3l3 3-3 3v3h-3l-3 3-3-3H6v-3Zm4.21-.25a.999.999 0 0 0 1.42 0l4.08-4.08a1.004 1.004 0 1 0-1.42-1.42l-3.37 3.38-1.21-1.22a1.004 1.004 0 0 0-1.42 1.42l1.92 1.92Z" clip-rule="evenodd"></path><path fill="#E0EFE0" d="M10.535 14.969a.998.998 0 0 1-.325-.219l-1.92-1.92a1.004 1.004 0 0 1 1.42-1.42l1.21 1.22 3.37-3.38a1.004 1.004 0 1 1 1.42 1.42l-4.08 4.08a.997.997 0 0 1-1.095.219Z"></path></svg>   </div>,
        text: <span className="text-5xl">$19<span className="text-2xl text-des">/mo</span></span>,
        icon: icon,
        line1: <p className="text-lg font-light text-text"><span className="font-bold">Unlimited</span> tokens/month</p>,
        line2: <p className="text-lg font-light text-text"><span className="font-bold">Unlimited</span> lines of code processed/ month</p>,
        line3: <p className="text-lg font-light text-text"><span className="font-bold">8,000 </span> token input limit</p>,
        line4: <p className="text-lg font-light text-text"><span className="font-bold">Priority </span> Most features</p>,
        button: <ButtonRed path='/' title='Get started' />
    },
]