import React, { useState, useContext, useEffect, useRef } from 'react'
import moment from 'moment';
import { dataBodySideMenu } from '../mocks/dataSideBar'
import { NavLink, useNavigate } from 'react-router-dom'
import { icon_starts, } from '../mocks/dataIcon.js'
import ShareQuery from '../components/ShareQuery'
import QuerySettings from '../components/QuerySettings'
import MainContext from './MainContext';
import { getDocs, where, collection, limit, orderBy, startAfter } from "firebase/firestore";
import { query } from "firebase/firestore";
import { firestore } from '../firebase/config'
import { getAccessToken, getToken } from '../services/helper/TokenHelper'
import { ToolConfig } from './Tools/ToolConfig';



const Dashboard = () => {
  const navigate = useNavigate()
  const [statusSettings, setStatusSettings] = useState(true)
  const [current, setCurrent] = useState(null)
  const [historyData, setHistoryData] = useState([]);
  const { statusQuery, handleClickQuery, setStatusQuery, dataInfo } = useContext(MainContext)
  const [latestDoc, setLastDocs] = useState(undefined)
  const shareQueryRef = useRef(null);

  const handleClickSettings = (index) => {
    setCurrent(index)
    setStatusSettings(!statusSettings)
  }

  const fetchhistoryData = async () => {
    const q = query(collection(firestore, `messages`),
      where("userEmail", "==", getToken('email')),
      where("inputs", "!=", null),
      orderBy('inputs'),
      orderBy('createdAt', 'asc'),
      startAfter(latestDoc || 0), limit(10))
    const docsSnap = await getDocs(q);
    setLastDocs(docsSnap.docs[docsSnap.docs.length - 1])
    const chats = docsSnap?.docs;
    if (chats) {
      const data = chats?.map(item => {
        return item.data()
      })
      setHistoryData([...historyData, ...data])
    }
  }

  const onChangeHistoryData = (data) => {
    setHistoryData(
      historyData.map((item, index) => {
        if (index === current) {
          return { ...data }
        }
        return item
      })
    )
  }

  useEffect(() => {
    async function getMessages() {
      await fetchhistoryData()
    }
    getMessages()
  }, [dataInfo])

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (shareQueryRef.current && !shareQueryRef.current.contains(event.target)) {
        setStatusQuery(false)
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  const loadmoreHistory = async () => {
    await fetchhistoryData()
  }

  return (
    <>
      <div className='relative'>
        <div className=' p-9 overflow-auto h-[calc(100vh-60px)] bg-grayForm'>
          <div className='max-w-[1212px] mx-auto'>
            <div className='mb-6'>
              <h1 className='text-text text-2xl mb-3 font-semibold font-main'>Tools</h1>
              <div className='flex flex-wrap  gap-3 '>
                {dataBodySideMenu.map((item, index) => (
                  <div className='relative rounded-sm overflow-hidden ' key={index}>
                    <NavLink to={`/tools/${item.path}`}
                      className='bg-bg_hover gap-4 w-[192px] h-[192px] rounded-sm border border-colorBorder hover:border-red hover:border 
                        duration-200 ease-linear cursor-pointer flex flex-col items-center justify-center 
                        before:hover:bg-red_blur before:absolute before:w-full before:h-full before:top-0 before:rounded-sm '>
                      <div className='bg-gray_body p-6 border-4 rounded-lg border-colorBorder flex flex-col items-center '>
                        <img className='z-10' src={item.icon} alt="" />
                      </div>
                      <h2 className='text-text '>{item.title}</h2>
                    </NavLink>
                  </div>
                ))}
              </div>
            </div>

            <div>
              <h1 className='text-text text-2xl mb-3 font-semibold font-main'>History</h1>
              <div>
                <div className="flex flex-wrap lg:flex-nowrap lg:flex-col gap-3">
                  {historyData && historyData.map((item, index) => {
                    return (
                      <div key={index} onClick={() => navigate(`${ToolConfig[item.promptType]?.url}?q=${item.chatId}`)}
                        className='relative rounded-md overflow-hidden w-[calc(50%-6px)] h-[192px] lg:w-full cursor-pointer '>
                        <div className='bg-bg_hover border border-colorBorder p-4  h-full rounded-md flex  hover:border-red hover:border
                                    before:hover:bg-red_blur before:absolute before:w-full before:h-full before:top-0 before:left-0 before:right-0 before:rounded-lg'>
                          <div className='min-w-[104px] mr-5 '>
                            <div className='bg-gray_body p-6 border-4 rounded-lg border-colorBorder '>
                              <img className='z-10 relative' src={ToolConfig[item.promptType]?.icon} alt="" />
                            </div>
                          </div>
                          <div className='w-[calc(100%-124px)] grow z-10'>
                            <div className='flex mb-6  justify-between'>
                              <div className='min-w-0'>
                                <p className='text-text text-base truncate  '>{item.title}</p>
                                <p className='text-des text-[13px] truncate'>{moment(new Date(item.createdAt?.seconds * 1000 + item.createdAt?.nanoseconds / 1000000)).format('MMMM Do YYYY  h:mm A')}</p>
                              </div>
                              <div>
                                <div className='flex gap-3'>
                                  <div className='bg-borderGray h-[34px] rounded-xs '>
                                    <button onClick={() => handleClickSettings(index)}
                                      className='bg-colorBorder text-white rounded-xs py-2 px-3 border  border-borderGray 
                                                                        cursor-pointer hover:opacity-80  ease-linear transform active:translate-y-[1px] transition-transform'>
                                      {icon_starts}
                                    </button>
                                  </div>
                                  <div className='bg-borderGray h-[34px] rounded-xs'>
                                    <button onClick={() => handleClickQuery(item.chatId)}
                                      className='bg-colorBorder text-white rounded-xs py-2 px-3 border  border-borderGray cursor-pointer hover:opacity-80 
                                                                        ease-linear transform active:translate-y-[1px] transition-transform'>
                                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"><path d="m6.473 4.473.86-.866v7.726a.667.667 0 1 0 1.334 0V3.607l.86.866a.666.666 0 0 0 1.092-.217.667.667 0 0 0-.146-.73l-2-2a.666.666 0 0 0-.946 0l-2 2a.67.67 0 1 0 .946.947ZM12 6h-1.333a.667.667 0 1 0 0 1.333H12a.667.667 0 0 1 .667.667v4.667a.667.667 0 0 1-.667.666H4a.667.667 0 0 1-.667-.666V8A.667.667 0 0 1 4 7.333h1.333a.667.667 0 1 0 0-1.333H4a2 2 0 0 0-2 2v4.667a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V8a2 2 0 0 0-2-2Z"></path></svg>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <p className='text-[13px] text-text text-ellipsis line-clamp-4 '>{item.text}</p>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </div>
                <div className="loadmore mt-6  ">
                  <div className='bg-borderGray h-[34px] w-[87px] rounded-xs'>
                    <button onClick={() => loadmoreHistory()}
                      className='text-text font-main text-xs font-semibold px-3 py-2  rounded-xs bg-bg_button border 
                                        border-borderGray transform active:translate-y-[1px] transition-transform'>Load more
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {statusQuery ?
            <div
              className='bg-bg_black_blur  absolute -top-[56px] min-h-screen w-min-screen right-0 -left-[300px] flex items-center justify-center z-40'>
              <div ref={shareQueryRef} className='w-[534px] h-[240px] z-50'>
                <ShareQuery />
              </div>
            </div>
            :
            <></>}
          {!statusSettings ?
            <div className='bg-bg_black_blur  absolute -top-[56px] min-h-screen w-min-screen right-0 -left-[300px] flex items-center justify-center z-40'>
              <div className='w-[534px] h-[240px] z-50'>
                <QuerySettings data={historyData[current]} setStatusSettings={setStatusSettings} onChangeHistoryData={onChangeHistoryData} />
              </div>
            </div>
            :
            <></>}
        </div>
      </div>
    </>
  )
}

export default Dashboard