import React from 'react'
import ToolComponents from './ToolsComponent'
import { ToolConfig } from './ToolConfig'


const CodeComparer = () => {
    return (
        <ToolComponents toolConfig={ToolConfig.CodeCompare}/>
    )
}

export default CodeComparer
