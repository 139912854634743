import { configApp } from '../AxiosConfig';
// import { typeUserInfo } from '../interface/user/user.interface';
// import AsyncLocalStorage from '@createnextapp/async-local-storage'


export const getToken = (key) => localStorage.getItem(key);
export const getUser = (key) => localStorage.getItem(key);
export const getEmail = (key) => localStorage.getItem(key);
export const getName = (key) => localStorage.getItem(key);
export const setToken = (key, value) => localStorage.setItem(key, value);
export const setUser = (key, value) => localStorage.setItem(key, value);
export const setEmail = (key, value) => localStorage.setItem(key, value);
export const setName = (key, value) => localStorage.setItem(key, value);

export const userInfoKey = 'useInfo';
const keyRememberMe = 'rememberMe';

export const getAccessToken = () => {
	return getToken(configApp.tokenKey);
};

export const getRefreshToken = () => {
	return getToken(configApp.refreshTokenKey);
};

export const getUserInfo = () => {
	return getUser(configApp.userInfo);
};
export const getEmailLogin = () => {
	return getEmail(configApp.email);
};
export const getNameLogin = () => {
	return getName(configApp.name);
};

export const setAccessToken = async (accessToken) => {
	await setToken(configApp.tokenKey, accessToken);
};

export const setEmailInfo = (data) => {
	return setEmail(configApp.email, data)
}
export const setNameLogin = async (data) => {
	return setName(configApp.name, data)
}
export const setUserInfo = async (data) => {
	return setUser(configApp.userInfo, data)
}

export const setRefreshToken = async (refreshToken) => {
	await setToken(configApp.refreshTokenKey, refreshToken);
};

export const deleteAccessToken = () => {
	localStorage.removeItem(configApp.tokenKey);
};

export const deleteRefreshToken = () => {
	localStorage.removeItem(configApp.refreshTokenKey);
};

export const getUserAndPassword = () => {
	try {
		const localInfo = localStorage.getItem(userInfoKey);
		const result = JSON.parse(localInfo);
		return {
			userName: result.userName,
			password: result.password,
		};
	} catch (error) {
		return {
			userName: '',
			password: '',
		};
	}
};

export const setUserAndPasswordLocal = (userInfo) => {
	localStorage.setItem(userInfoKey, JSON.stringify(userInfo));
};

export const deleteUserAndPasswordLocal = () => {
	localStorage.removeItem(userInfoKey);
};

export const setRememberMe = (value) => {
	localStorage.setItem(keyRememberMe, value);
};

export const getRememberMe = () => localStorage.getItem(keyRememberMe);

export const deleteRememberMe = () => {
	localStorage.removeItem(keyRememberMe);
};
