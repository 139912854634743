import React, { useEffect } from 'react'
import { NavLink } from 'react-router-dom';
import logo from '../../assets/images/logo.svg';
import { NavMenu } from '../../mocks/dataHeader.js';

const Footer = () => {

    const handeClick = () => {
        const element = document.querySelector('.price');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }

    useEffect(() => {
        if(window.location.href.includes('pricing')){
            handeClick()
        }
      }, []);
    


    return (
        <div className='pt-12 pb-[72px] w-full flex items-end justify-between bg-black  px-[16rem] md:px-8 md:h-[192px] lg:px-16'>
            <div className=''>
                <div className='min-w-[137px] mr-5 h-10'>
                    <img className='' src={logo} alt="logo" />
                </div>
                <div className='flex items-center text-grayText font-main gap-10 xs:gap-[24px] text-sm'>
                    {NavMenu.map((item, index) => {
                        if (item.name === "Pricing" && window.location.href.includes('pricing') ) {
                            return (
                                <div key={index}>
                                    <NavLink onClick={handeClick} >
                                        <div className="hover:decoration-1 hover:underline hover:decoration-grayButton  ">
                                            <span className='text-grayButton'>{item.name}</span>
                                        </div>
                                    </NavLink>
                                </div>
                            )
                        }
                        return (
                            <div key={index} className=''>
                                <NavLink to={item.path} >
                                    <div className="hover:decoration-1 hover:underline hover:decoration-grayButton   ">
                                        <span className='text-grayButton'>{item.name}</span>
                                    </div>
                                </NavLink>
                            </div>
                        )
                    })}
                </div>
            </div>
            <p className='text-[#5A5857] font-medium text-right text-xs sm:hidden '>*codefy.ai reserves the right to restrict <br />
                or terminate accounts in the case of abuse.
            </p>
        </div>
    )
}

export default Footer