import React, { useContext } from 'react';
import Conversation from './Conversation';


const NextConversation = (props) => {
    const { handleRun, statusRun, setTextConversation, statusConversation, backgb, resultsData } = props;


    return (
        <>
            {resultsData[0]?.title !== 'Output' || resultsData[0]?.title !== '...' ?
                <div className='text-white  px-9 pt-6 lg:px-5'>
                    <Conversation
                        statusRun={statusRun}
                        backgb={backgb}
                        setTextConversation={setTextConversation}
                        handleRun={handleRun}
                        statusConversation={statusConversation}
                        title='Continue the Coversation'
                        place1="Output not quite right? Need more? Engage in a conversation." />
                </div>
                :
                <></>
            }
        </>
    )
}

export default NextConversation