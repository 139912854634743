import React, { useRef } from 'react';
import { note, file_upload, } from '../mocks/dataIcon';
import { useState } from 'react';

import CodeEditor from '@uiw/react-textarea-code-editor';
import rehypePrism from "rehype-prism-plus";
import rehypeRewrite from "rehype-rewrite";

const YourCode = (props) => {
  const inputRef = useRef(null);
  const [fileName, setFileName] = useState(null);
  const [editorLanguage, setEditorLanguage] = useState('jsx')
  const [pasteContent, setPasteContent] = useState('')

  const handleClick = () => {
    inputRef.current?.click();
  };

  const { des, title, messages, setMessages } = props;

  const handleFileChange = event => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsText(file)
    reader.onload = () => {
      setFileName(file.name);
      setMessages(reader.result);
    }
    if (!file) {
      return;
    }
    event.target.value = null;
  };

  const handleChangeLanguage = (e) => {
    setEditorLanguage(e.target.value)
  }
  // method paste
  const clickPaste = () => {
    navigator.clipboard.readText().then(cliptext => {
      return (setPasteContent(cliptext), setMessages(cliptext))
    },
      err => console.log(err)
    )
  }

  return (
    <div className='mb-3'>
      <h1 className='text-text text-lg font-main font-semibold mb-2'>{title ? title : ''}</h1>
      <p className='text-des text-sm font-iter mb-4'>{des ? des : ''}</p>

      <div className='rounded-sm overflow-hidden border-gray_body border  bg-bg_hover
                focus-within:border-red  focus-within:outline-none min-w-[250px]'>
        <div className='px-[6px] flex justify-between h-9 border-b border-gray_body '>
          <div className=' flex  items-center'>
            <div className='flex items-center py-1 px-2 rounded-sm cursor-pointer hover:opacity-80 duration-200 ease-linear  mr-2  '
              onClick={clickPaste}>
              <span className='text-des'>{note ? note : ''}</span>
              <span className='text-des font-semibold text-xs ml-1'>Paste</span>
            </div>
            <div
              onClick={handleClick}
              className="flex w-full h-screen items-center justify-center ">
              <label className=" flex  items-center   text-blue  tracking-wide  cursor-pointer hover:opacity-80 duration-200 ease-linear mr-2 ">
                <span className='text-des'>{file_upload}</span>
                <span className='text-des font-semibold text-xs ml-1'>Upload</span>
                <input type='file' className="hidden" onChange={handleFileChange} />
              </label>
            </div>
          </div>
          <select className="flex items-center  rounded-xs max-w-[88px] pl-[2px] h-6 my-auto cursor-pointer hover:opacity-80 duration-200 ease-linear bg-bg_close
                        text-des font-main text-xs font-bold focus:outline-none"
            onChange={handleChangeLanguage}>
            <option value="jsx" className='text-des font-semibold text-xs mr-1 font-main'>Javascript</option>
            <option value="cpp">C++</option>
            <option value="csharp">C#</option>
            <option value="css">CSS</option>
            <option value="java">Java</option>
            <option value="json">JSON</option>
            <option value="jsx">JSX</option>
            <option value="go">Go</option>
            <option value="markdown">HTML</option>
            <option value="matlab">MATLAB</option>
            <option value="php">PHP</option>
            <option value="python">Python</option>
            <option value="r">R</option>
            <option value="regex">Regex</option>
            <option value="ruby">Ruby</option>
            <option value="rust">Rust</option>
            <option value="sql">SQL</option>
            <option value="swift">Swift</option>
            <option value="tsx">TSX</option>
            <option value="typescript">TypeScript</option>
          </select>
        </div>

        <CodeEditor
          value={messages || pasteContent}
          language={editorLanguage}
          placeholder='console.log("Hello world!")'
          onChange={(e) => setMessages(e.target.value)}
          padding={15}
          style={{
            fontSize: 14,
            backgroundColor: "#201E1E",
            color: '#706E6E',
            fontFamily: 'JetBrains Mono, monospace',

          }}
          rows={10}
          minHeight={'200px'}
          data-color-mode='dark'
          rehypePlugins={[
            [rehypePrism, { ignoreMissing: true }],
            [
              rehypeRewrite,
              {
                rewrite: (node, index, parent) => {
                  if (node.properties?.className?.includes("code-line")) {
                    if (index === 0 && node.properties?.className) {
                      node.properties.className.push("demo01");
                    }
                  }
                  if (node.type === "text" && node.value === "return" && parent.children.length === 1) {
                    parent.properties.className.push("demo123");
                  }
                }
              }
            ]
          ]}
        />
      </div>
    </div>
  )
}

export default YourCode