import React from 'react'
import ToolComponents from './ToolsComponent'
import { ToolConfig } from './ToolConfig'


const JSONGenerator = () => {
    return (
        <ToolComponents toolConfig={ToolConfig.JSONGenerator}/>
    )
}

export default JSONGenerator
