import React from 'react'
import ToolComponents from './ToolsComponent'
import { ToolConfig } from './ToolConfig'


const CodeDebugger = () => {
    return (
        <ToolComponents toolConfig={ToolConfig.codeDebugger}/>
    )
}

export default CodeDebugger
