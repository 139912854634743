import React from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import {
	getAccessToken,
	getRefreshToken,
	getToken,
	setAccessToken,
	deleteAccessToken,
	deleteRefreshToken,
	setRefreshToken,
} from '../services/helper/TokenHelper';
import { configApp } from '../services/AxiosConfig'


const keyHeaderCheckAuth = 'x-request-fail';
// const dispatch = useDispatch();
//  const nextToLogin = () => {
// 	const navigate = useNavigate();
// 	navigate('/login');
// };

const apiClient = axios.create({
	baseURL: `${configApp.hostApi}/${configApp.apiPrefix}/`,
	headers: {
		'content-type': 'application/json',
	},
});

apiClient.interceptors.request.use(function (config) {
	const accessToken = getAccessToken();
	if (accessToken) {
		config.headers.Authorization = 'Bearer ' + accessToken;
		// config.headers[keyHeaderCheckAuth] = 0;
	}
	return config;
});

apiClient.interceptors.response.use(
	(response) => response,
	async (error) => {
		if (error.response?.status === 401) {
			try {
				const refreshAxios = axios.create({
					baseURL: `${configApp.hostApi}/${configApp.apiPrefix}/`,
					headers: { 'Authorization': `Bearer ${getRefreshToken()}` }
				});
				const res = await refreshAxios.post(`/v1/auth/refresh`)
				setRefreshToken(res?.data?.refreshToken);
				setAccessToken(res?.data?.token);
				window.location.reload()
			} catch (error) {
				deleteAccessToken()
				deleteRefreshToken()
			}
		}
		// This is important, so that unhandled cases make axios throw errors
		return Promise.reject(error);
	}
);
export default apiClient;
