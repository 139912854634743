
export const NavMenu = [
    {
        name: 'Follow @codefyai',
        path:'https://twitter.com/i/flow/login?redirect_after_login=%2Fintent%2Ffollow%3Fscreen_name%3Dcodefyai',
        target:""
    },
    {
        name: 'Pricing',
        path: '/pricing',
        
    },
    {
        name: 'Sign In / Register ',
        path:'/login'
    },
]
