import React, { useState, useRef, useEffect, useContext } from "react";
import MainContext from "../pages/MainContext";
import { ButtonCoversation } from './ButtonRed';
import { icon_play, } from '../mocks/dataIcon';

const Conversation = (props) => {
    const { title, place1, place2, place3, handleRun, statusRun, setTextConversation, backgb } = props;
    const { val, setVal } = useContext(MainContext)
    const [statusConversation, setStatusConversation] = useState(true);

    const textAreaRef = useRef(null);

    const handleClickConversation = () => {
        setStatusConversation(!statusConversation);
    }

    const resizeTextArea = () => {
        textAreaRef.current.style.height = "auto";
        textAreaRef.current.style.height = textAreaRef.current.scrollHeight + "px";
    };

    useEffect(resizeTextArea, [val]);

    const onChange = e => {
        setVal(e.target.value);
        setTextConversation(e.target.value)
    };

    useEffect(()=>{
        if(statusRun){
            setVal('');
        }
    }, [statusRun])
    
    return (
        <div className="mb-3 ">
            <div className={` rounded-sm overflow-hidden border-gray_body border  bg-bg_close
            ${!statusConversation && handleRun ? '  ' : 'focus-within:border-red'}  focus-within:outline-red min-w-[250px]`}>
                <div className="flex justify-between px-3 border border-gray_body h-12 rounded-t-sm">
                    <div className='flex font-main items-center'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"><path d="M12.667 5.333H12v-2a2 2 0 0 0-2-2H3.333a2 2 0 0 0-2 2v8a.667.667 0 0 0 .414.614.666.666 0 0 0 .727-.14l1.873-1.88h.986v.96a2 2 0 0 0 2 2h4.614l1.58 1.586a.667.667 0 0 0 1.026-.104.666.666 0 0 0 .114-.369V7.333a2 2 0 0 0-2-2Zm-7.333 2v1.26h-1.26a.667.667 0 0 0-.474.194l-.933.94V3.333a.667.667 0 0 1 .667-.666H10a.667.667 0 0 1 .667.666v2H7.334a2 2 0 0 0-2 2Zm8 5.06-.667-.666a.666.666 0 0 0-.473-.2h-4.86a.667.667 0 0 1-.667-.667V7.333a.667.667 0 0 1 .667-.666h5.333a.667.667 0 0 1 .667.666v5.06Z"></path></svg>
                        <h1 className='text-text text-lg font-main font-semibold ml-3'>{title}</h1>
                    </div>
                    <div className="flex items-center" onClick={handleRun} style={{ pointerEvents: !statusRun ? 'none' : 'inherit' }}>
                        <ButtonCoversation
                            handleClickConversation={handleClickConversation}
                            statusConversation={statusConversation}
                            title="Run" icon={icon_play} backgb={backgb} border='transparent' name='white' />
                    </div>

                </div>
                <textarea
                    ref={textAreaRef}
                    val={val}
                    value={val}
                    maxLength={1000}
                    className='w-full resize-none bg-bg_close p-3  text-text text-sm placeholder:text-sm placeholder:text-grayButton outline-none '
                    rows="3" multiline={true}
                    placeholder={`${place1}\n${place2 ? place2 : ''}\n${place3 ? place3 : ''}`}
                    onChange={onChange}>
                </textarea>
            </div>

        </div>
    )
}

export default Conversation