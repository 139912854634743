import React from 'react'

import LogginForm from '../components/LogginForm';
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';


const Login = () => {
    return (
        <div className='flex flex-col flex-1 relative  '>
            <Header />
            <div className='px-8 py-16 xs:py-0 flex flex-col grow items-center justify-center   '>
                <LogginForm />
            </div>
            <div className=''>
                <Footer />
            </div>
        </div>
    )
}

export default Login