import React from 'react'
import MainLayout from '../layouts/MainLayout'
import useAuth from './Hooks/useAuth'
import MainLayOutLogin from '../layouts/MainLayOutLogin'
import Dashboard from './Dashboard'
import Home from './Home'

const MainPage = () => {
    const isLogin = useAuth()

    return (
        <>
            {isLogin ? <MainLayout ChildComponent={<Dashboard />}/> : <MainLayOutLogin ChildComponent={<Home />}/>}
        </>

    )
}

export default MainPage