import React from 'react'
import ToolComponents from './ToolsComponent'
import { ToolConfig } from './ToolConfig'


const CodeRefactoring = () => {
    return (
        <ToolComponents toolConfig={ToolConfig.CodeRefactoring}/>
    )
}

export default CodeRefactoring
