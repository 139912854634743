import React, { useState } from 'react'
import { dataCollapse } from './../mocks/dataCollapseList';

const CollapseList = () => {
  const [data, setData] = useState(dataCollapse)

  const handleClickShow = (index,) => {

    const editData = data?.map((item, _index) => {
      if (_index === index) {
        return { ...item, isOpen: !item.isOpen }
      } else {
        return item
      }
    })
    setData(editData)
  }

  return (
    <div className="max-w-screen-xl mx-auto px-5 min-h-sceen">
      <div className="flex justify-start ">
        <h2 className="font-bold text-5xl font-main text-grayButton h-16 mb-6 ">
          FAQ
        </h2>
      </div>
      <div className="  mx-auto ">
        {data?.map((item, index) => {
          return (
            <div key={index} className=" font-main  ">
              <div onClick={() => handleClickShow(index)}
                className={`flex justify-between  items-center font-medium cursor-pointer list-none py-5 px-3  ${item.status ? 'border-b-0' : 'border-b-2'} border-b-gray_body`}>
                <p className=' text-text text-2xl font-bold hover:decoration-solid hover:underline  duration-300 ease-linear  hover:decoration-white'> {item.title}</p>
                <span className={`${item.isOpen ? 'rotate-180' : ''} transition duration-200 text-grayButton`}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"><path d="m17 10.59-4.29 4.24a.998.998 0 0 1-1.42 0l-4.24-4.24a1 1 0 1 1 1.41-1.42L12 12.71l3.54-3.54a1 1 0 0 1 1.41 0 1 1 0 0 1 .05 1.42Z"></path></svg>
                </span>
              </div>
              <p className={`${item.isOpen ? 'h-full active-pricing opacity-100  bg-gray_body p-3' : 'h-0  opacity-0 hidden p-0 '}  text-neutral-600    text-text duration-300 ease-in-out    `}>
                {item.des}
              </p>

            </div>
          )
        })}
      </div>


      <script>
        {/* // ...
	// extend: {
    //   keyframes: {
    //     fadeIn: {
    //       "0%": { opacity: 0 },
    //       "100%": { opacity: 100 },
    //     },
    //   },
    //   animation: {
    //     fadeIn: "fadeIn 0.2s ease-in-out forwards",
    //   },
    // },
    // ... */}
      </script>
    </div>
  )
}

export default CollapseList