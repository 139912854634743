import React, { useState, useEffect, useContext } from 'react';
import iconGoogle from '../assets/images/icons8-google.svg';
import { InfoUser } from '../services/AuthService';
import MainContext from '../pages/MainContext';



export const ButtonRed = (props) => {
    const { title, path } = props;

    return (
        <div className='text-center mx-auto hover:scale-110 duration-100 ease-in'>
            <a href={path} className='text-white font-main rounded-xs bg-red h-[56px] w-[256px] mx-auto font-bold text-2xl  flex items-center justify-center shadow-bt'>{title}</a>
        </div>
    )
}
export const ButtonUp = (props) => {
    const { title, } = props;

    return (
        <div className={`text-center  duration-100 ease-in transform active:translate-y-[1px] transition-transform `}>
            <div className='bg-bg_red h-[51px] rounded-xs p-[1px] cursor-pointer hover:opacity-90 duration-75'>
                <span className='text-white font-main rounded-xs bg-red h-12  mx-auto font-bold text-base  flex items-center justify-center '>
                    {title}
                </span>
            </div>
        </div>
    )
}

export const ButtonLogin = (props) => {
    const { handleLogin, } = props

    return (
        <button
            onClick={handleLogin}

            className={`h-12 w-full rounded-sm text-center  mx-auto text-white font-bold flex items-center justify-center bg-red transform active:translate-y-[1px] transition-transform  `}>
            <svg className="h-8 w-8 mr-4" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2"
                strokeLinecap="round" strokeLinejoin="round">  <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z" />
                <polyline points="22,6 12,13 2,6" />
            </svg>
            <span className='text-white font-main'>Continue with Email</span>

        </button>
    )
}


export const ButtonGoogle = () => {
    return (
        <div className='h-12 rounded-sm text-center bg-text  mx-auto text-black font-bold flex items-center justify-center cursor-pointer transform active:translate-y-[1px] transition-transform'>
            <img className='w-8 h-8 mr-4' src={iconGoogle} alt="" />
            <span className='text-black font-main'>Continue with Google</span>

        </div>
    )
}

export const ButtonComponent = (props) => {
    const { title, bg, text, color } = props;
    return (
        <div className={`mx-1 h-[34px] bg-${color} rounded-xs`}>
            <button className={`  font-main text-xs font-semibold text-white py-2 px-3 bg-${bg}  rounded-xs cursor-pointer hover:opacity-80 duration-200 ease-linear
        transform active:translate-y-[1px] transition-transform `}>
                <span className={`text-${text} font-semibold`}>{title}</span>
            </button>

        </div>
    )
}
export const ButtonFeedback = (props) => {
    const { title, bg, text, handleClickButton } = props;
    return (
        <div className={`bg-borderGray  h-[35px]  rounded-xs`}
            onClick={handleClickButton}>
            <button aria-label='' className={`font-main text-xs font-semibold text-white py-2 px-3 bg-${bg} border-borderGray border rounded-xs cursor-pointer hover:opacity-80 duration-75 ease-linear
            transform active:translate-y-[1px] transition-transform `}>
                <a href="mailto:contact@codefy.ai?subject=codefy.ai%20bulk%20inquiry" target='' className={`mx-2`}>
                    <span className={`text-${text} font-semibold`}>{title}</span>
                </a>
            </button>

        </div>
    )
}
export const ButtonUpgrade = (props) => {
    const { title, bg, text, handleClickButtonUpgrade } = props;
    return (
        <div className={`bg-bg_red  h-[35px] min-w-[102px]   rounded-xs`}
            onClick={handleClickButtonUpgrade}>
            <button className={` font-main text-xs font-semibold text-white py-2 px-3 bg-${bg} border-${bg} border rounded-xs cursor-pointer hover:opacity-80 duration-75 ease-linear 
            transform active:translate-y-[1px] transition-transform`}>
                <span className={`text-${text} font-semibold w-[78px] block`}>{title}</span>
            </button>

        </div>
    )
}
export const ButtonUpgradeTitle = (props) => {
    const { title, bg, text, handleClickButtonUpgrade } = props;
    return (
        <div className={`bg-bg_red  h-[35px] w-[200px]  rounded-xs`}
            onClick={handleClickButtonUpgrade}>
            <button className={`  w-[200px] h-8  font-main text-xs font-semibold text-white py-2 px-3 bg-${bg} border-${bg} border 
            rounded-xs cursor-pointer hover:opacity-80 duration-75 ease-linear transform active:translate-y-[1px] transition-transform `}>
                <span className={`text-${text} font-semibold`}>{title}</span>
            </button>

        </div>
    )
}

export const ButtonQuery = (props) => {
    const { titleButton, icon, borderWidth, resultsData, title, chatId } = props;
    const { handleClickQuery, statusRun } = useContext(MainContext);

    return (
        <div className={`${resultsData && title !== 'Output' ? 'bg-borderGray' : 'bg-bg_button'}  min-w-[120px] 
        ${resultsData && title ? 'h-[35px] ' : ''}   rounded-xs`} onClick={resultsData && title !== 'Output' ? ()=>handleClickQuery(chatId) : null} style={{ pointerEvents: !statusRun ? 'none' : 'inherit' }} >
            <button
                className={`  duration-75 text-xs font-main font-semibold  py-2 px-3 bg-bg_button
                ${title !== 'Output' ? 'border-borderGray' : 'border-grayButton'} ${borderWidth}  
                rounded-xs cursor-pointer hover:opacity-80  ease-linear flex items-center justify-between transform active:translate-y-[1px] transition-transform `}>
                <span className={` ${title !== 'Output' ? 'text-text' : 'text-grayButton'} font-semibold w-[71px]`}>{titleButton}</span>
                <span className={`ml-2  ${title !== 'Output' ? 'text-text' : 'text-grayButton'} font-semibold`}>{icon}</span>
            </button>

        </div>
    )
}
export const ButtonDelQuery = (props) => {
    const { titleButton, backgb, icon, border, borderWidth, } = props;

    return (
        <div className={`bg-borderGray  w-[125px] h-[33px]   rounded-xs`}  >
            <button
                className={` w-[125px] h-8  duration-75 text-xs font-main font-semibold  py-2 px-3 bg-${backgb} border-${border} ${borderWidth}  
                rounded-xs cursor-pointer hover:opacity-80  ease-linear flex items-center justify-between transform active:translate-y-[1px] transition-transform`}>
                <span className={`ml-2 text-text  font-semibold`}>{icon}</span>
                <span className={`text-text  font-semibold`}>{titleButton}</span>
            </button>

        </div>
    )
}

export const ButtonRun = (props) => {
    const { title, backgb, icon, border, name, borderWidth, handleClickRun, statusRun, } = props;
  

    return (
        <div className={`${statusRun ? 'bg-bg_red h-[33px] ' : 'bg-borderGray h-[33px] '}  rounded-xs`} onClick={handleClickRun}>

            <button className={` duration-75 text-xs font-main font-semibold  py-2 px-3 bg-${backgb} border-${border} ${borderWidth}  
            rounded-xs cursor-pointer hover:opacity-80  ease-linear flex items-center justify-between transform active:translate-y-[1px] transition-transform `}>
                <span className={`text-${name} font-semibold`}>{title}</span>
                <span className={`ml-2 text-${name} font-semibold`}>{icon}</span>
            </button>
        </div>
    )
}
export const ButtonCoversation = (props) => {
    const { title, backgb, icon, border, name, borderWidth, handleClickConversation } = props;

    return (
        <div className={`${backgb === 'red' ? 'bg-bg_red h-[33px] ' : 'bg-borderGray h-[33px] '}  rounded-xs`} onClick={handleClickConversation}>

            <button className={`  duration-75 text-xs font-main font-semibold  py-2 px-3 bg-${backgb} border-${border} ${borderWidth}  
            rounded-xs cursor-pointer   ease-linear flex items-center justify-between transform active:translate-y-[1px] transition-transform `}>
                <span className={`text-${name} font-semibold`}>{title}</span>
                <span className={`ml-2 text-${name} font-semibold`}>{icon}</span>
            </button>
        </div>
    )
}