import React, { useState, useRef, useEffect } from "react";

const Optional = (props) => {
    const { title, des, place1, place2, place3, setOptional, defaultValue } = props;
    const [val, setVal] = useState(undefined);

    const textAreaRef = useRef(null);

    const resizeTextArea = () => {
        textAreaRef.current.style.height = "auto";
        textAreaRef.current.style.height = textAreaRef.current.scrollHeight + "px";
    };

    useEffect(resizeTextArea, [val]);

    const onChange = e => {
        setVal(e.target.value);
        setOptional(e.target.value)
    };

    useEffect(() => {
        if(val === undefined){
            setVal(defaultValue);
        }
    });

    return (
        <div className='mb-3'>
            <div className='flex font-main items-center mt-4'>
                <h1 className='text-text text-lg font-main font-semibold mb-2 mr-1 h-6'>{title}</h1>
                <span className='text-sm text-grayButton h-6'>(optional)</span>
            </div>
            <p className='text-des text-sm font-iter mb-4'>{des}</p>
            <textarea
                style={{ overflow: 'hidden' }}
                ref={textAreaRef}
                value={val || defaultValue}
                className='w-full resize-none bg-bg_hover p-3 border text-text text-sm border-gray_body rounded-sm 
            placeholder:text-sm placeholder:text-grayButton focus:outline-none  focus:border-red '
                rows="3" multiline={true}
                placeholder={`${place1 ? place1 : ''}\n${place2 ? place2 : ''}\n${place3 ? place3 : ''}`}
                onChange={onChange}>
            </textarea>
        </div>
    )
}

export default Optional