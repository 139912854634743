import React, { useEffect } from 'react'
import { getDocs, where, collection } from "firebase/firestore";
import { query } from "firebase/firestore";
import { firestore } from '../firebase/config'
import { useParams, useNavigate } from "react-router-dom";
import { ToolConfig } from './Tools/ToolConfig';


const RedirectQuery = () => {
    const { chatId } = useParams()
    const navigate = useNavigate()

    useEffect(() => {
        async function getMessages() {
            const q = query(collection(firestore, `messages`),
                where("chatId", "==", chatId)
            )
            const docsSnap = await getDocs(q);
            const message = docsSnap?.docs[0]?.data()
            navigate(ToolConfig[message.promptType]?.url + '?q=' + chatId)
        }
        getMessages()
    })

}

export default RedirectQuery