import React, { useEffect} from 'react'
import { useNavigate } from "react-router-dom";

import Product_hunt from '../assets/images/product_hunt.svg'
import { ButtonRed } from '../components/ButtonRed'
import dashboard from '../assets/images/dashboard.svg'
import customtools from '../assets/images/customtools.svg'
import icon from '../assets/images/icon.svg'
import { DataHome } from '../mocks/dataHome.js'
import SliderImage from '../components/SliderImage'
import Header from '../components/Header/Header'
import Footer from '../components/Footer/Footer';
import useAuth from './Hooks/useAuth'


const Home = () => {
    const auth = useAuth();
    const navigate = useNavigate();

    return (
        <div className=''>
            <div className='min-h-[650px] h-[80vh] bg_main  relative'>
                <Header />
                <h1 className='text-[64px] lg:text-[32px] w-full text-center text-white font-bold font-main px-4 leading-[80px] lg:leading-[3rem] mb-8'><span className='text-red'>AI is</span> changing the game. <br />
                    Use it to <span className='text-red'>your advantage.</span></h1>
                <div className='px-8 font-main '>
                    <p className='text-text px-4 text-xl lg:text-base font-light text-center leading-8'>Accelerate your coding journey with a suite of AI-powered tools.</p>
                    <p className='text-red px-4 text-xl lg:text-base text-center font-light leading-8'>All languages supported!</p>
                    <div className='mt-12 flex justify-center'>
                        <ButtonRed path='/pricing' title='Get started free' />
                    </div>
                    <div className='text-center absolute bottom-6 left-0 right-0'>
                        <p className='text-[#E0DEDE] text-base '>Trusted by <span className='font-black'>thousands</span>  of developers.</p>
                        <div className='hover:text-red duration-300'>
                            <a href='#slider_image' className='text-[#E0DEDE] flex text-base flex-col items-center  hover:text-red duration-200 cursor-pointer mb-5 font-black'>
                                See it in action.
                                <svg class="h-4 w-4 text-red-500 text-center" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">  <polyline points="7 13 12 18 17 13" />  <polyline points="7 6 12 11 17 6" /></svg>
                            </a>
                        </div>
                    </div>
                    <div className='flex  justify-end mb-6 cursor-pointer absolute bottom-0 sm:-bottom-[32px] right-4'>
                        <a href="https://www.producthunt.com/products/codefy-ai#codefy-ai" target="popup" >
                            <img src={Product_hunt} alt="" />
                        </a>
                    </div>
                </div>
            </div>

            <div id='slider_image' className='w-full h-full bg-[#100F0E] pb-10'>
                <div className=' pb-8 pt-24 flex justify-center w-body mx-auto'>
                    <div className='mb-5 '>
                        <div className='w-body'>
                            <SliderImage />
                        </div>
                        <div className='mt-24 pb-12 flex justify-center '>
                            <ButtonRed path='/pricing' title='Get started free' />
                        </div>

                    </div>

                </div>
                <div className='border border-red w-[55%] lg:w-[80%] overflow-hidden  mx-auto rounded-md shadow-lg pb-10 '>
                    <img className='' src={dashboard} alt="" />
                </div>

                <div className='flex flex-col items-center mt-20'>
                    {DataHome.map((item, index) => {
                        return (
                            <div key={index} className='flex items-center mb-10 lg:flex-col xs:mx-2'>
                                <img className='w-[328px]' src={item.image} alt="" />
                                <div className='text-white font-main h-full ml-20 lg:ml-0 lg:mt-8 lg:text-center px-4 xs:px-0 max-w-[467px]  xs:mx-4'>
                                    <h2 className='text-2xl mb-6 font-semibold xs:mx-4'>{item.title}</h2>
                                    <p className='text-xl text-grayText xs:mx-4 '>{item.des}</p>
                                </div>
                            </div>
                        )
                    })}
                </div>
                <div className='mb-14'>
                    <ButtonRed path='/pricing' title='Get started free' />

                </div>

            </div>
            <div className='bg-brown pt-[96px] h-[544px] lg:h-full lg:py-16 w-full'>
                <div className='gap-24 lg:gap-8 flex mx-auto justify-center lg:justify-between lg:flex-col'>
                    <div className='px-4  lg:w-full flex lg:justify-center text-xl'>
                        <div className='w-[413px] px-4 xs:px-0 font-main lg:text-center'>
                            <div className='flex mb-12 lg:mb-8 items-center '>
                                <img src={icon} alt="" className='mr-2' />
                                <h2 className=' text-2xl font-main font-bold text-text'>Coming Soon</h2>
                            </div>
                            <h2 className='text-2xl mb-6 text-text font-bold lg:text-center'>Engineer your own tools</h2>
                            <p className='text-text text-xl'>Are you a maker at heart? codefy.ai <br /> <span className='font-bold'>custom tools </span>
                                allows you to create your own tool pipelines and share them with the community.</p>

                        </div>
                    </div>
                    <div className='lg:w-full max-h-[362px] max-w-[522px]'>
                        <img src={customtools} alt="" className='lg:mx-auto md:w-[80%]  px-4' />
                    </div>
                </div>
            </div>
            <div className=' bg-black md:h-[536px]'>
                <div className="bg_part  pt-32 pb-16   ">
                    <h1 className='text-center text-[64px] md:text-[32px] font-main font-semibold text-text lg:h-[10rem] md:h-full'>AI is here to stay.<br />
                        <span className='text-red'>Embrace it.</span>
                    </h1>
                    <div className='mt-24'>
                        <ButtonRed path='/pricing' title='Get started free' />
                    </div>
                </div>
            </div>
            <Footer />

        </div>
    )
}

export default Home