import React, { useState, useRef, useEffect } from 'react'

const FormInfo = (props) => {
    const { title, des, place1, place2, place3, setMessages, defaultValue } = props;

    const [val, setVal] = useState(undefined);
    const textAreaRef = useRef(null);

    const resizeTextArea = () => {
        textAreaRef.current.style.height = "auto";
        textAreaRef.current.style.height = textAreaRef.current.scrollHeight + "px";
    };


    const onChange = e => {
        setVal(e.target.value);
        setMessages(e.target.value)
    };

    useEffect(resizeTextArea, [val]);
    useEffect(() => {
        if(val === undefined){
            setVal(defaultValue);
        }
    });

    return (
        <div className='mb-3  '>
            <h1 className='text-text text-lg font-main font-semibold mb-2'>{title ? title : ''}</h1>
            <p className='text-des text-sm font-iter mb-4'>{des}</p>
            <textarea className='w-full resize-none bg-bg_hover p-3 border text-text border-gray_body rounded-sm placeholder:text-sm 
            focus:outline-none placeholder:text-grayButton  focus:border-red text-sm'
                rows="3" multiline={true}
                ref={textAreaRef}
                value={val}
                placeholder={`${place1 ? place1 : ''}\n${place2 ? place2 : ''}\n${place3 ? place3 : ''}`}
                onChange={onChange}
                style={{ overflow: 'hidden' }}
            >
            </textarea>
        </div>
    )
}

export default FormInfo