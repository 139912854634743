import React, { useState } from 'react';
import { icon_close, icon_delete } from '../mocks/dataIcon';
import { ButtonUpgradeTitle, ButtonDelQuery } from './ButtonRed';

const QuerySettings = (props) => {
    const { data, setStatusSettings, onChangeHistoryData,  } = props;



    const handleClickClose = () => {
        onChangeHistoryData(data)
        setStatusSettings(true);
        
    }
    const handleClickUpdate = () => {
        setStatusSettings(true)
        
    }
    
    const onChange = (e) => {
        onChangeHistoryData({ ...data, title: e.target.value })
    }

    return (
        <div className='text-white bg-grayForm border border-colorBorder rounded-md  w-[420px] overflow-hidden '>
            <div className='flex items-center justify-between border-b border-colorBorder bg-bg_hover  px-4'>
                <h1 className=' text-text text-lg font-semibold font-main h-[56px] leading-[56px] '>Query Settings</h1>
                <span className='p-2 cursor-pointer hover:bg-[#2A2827] duration-300 ease-linear rounded-sm'
                    onClick={handleClickClose}>{icon_close}
                </span>
            </div>
            <div className='p-8 flex justify-between '>
                <div>
                    <input type="text"
                        className='bg-bg_hover p-3 text-text text-sm font-main border border-gray_body rounded-xs focus:outline-none'
                        onChange={onChange}
                        value={data.title} />
                    <div className='mt-2' onClick={handleClickUpdate}>
                        <ButtonUpgradeTitle title="Update Title" bg='red' />
                    </div>

                </div>
                <div>
                    <ButtonDelQuery
                        titleButton="Share Query"
                        icon={icon_delete}
                        backgb='bg_button'
                        border='grayText'
                        name='grayButton'
                        borderWidth='border' />

                </div>

            </div>

        </div>
    )
}

export default QuerySettings