import React, { useRef, useState, useContext } from 'react';
import { ButtonUpgrade, ButtonFeedback } from '../../components/ButtonRed'
import { useLocation, useNavigate } from 'react-router-dom';
import { dataBodySideMenu } from '../../mocks/dataSideBar.js';
import { icon_setting, icon_logout } from '../../mocks/dataIcon';
import { getEmailLogin, setAccessToken, setRefreshToken, setUserInfo, setEmailInfo } from '../../services/helper/TokenHelper'
import avartar from '../../assets/icons/icon_user.png';
import MainContext from '../../pages/MainContext';


const HeaderLogin = () => {

    const { pathname } = useLocation();
    const path = pathname.split('/')[2];
    const [open, setOpen] = useState(false);
    const [statusFeed, setStatusFeed] = useState(true);
    const [statusUpgrade, setStatusUpgrade] = useState(true);
    const { handleClickUp, handleClickAccount, dataInfo, checkLogin, setDataInfo } = useContext(MainContext)
    const navigate = useNavigate()
    const menuRef = useRef();
    const imgRef = useRef();

    const handleClickButton = () => {
        setStatusFeed(!statusFeed)
    }

    const handleClickButtonUpgrade = () => {
        if(checkLogin()){
            setStatusUpgrade(!statusUpgrade)
        }
    }

    const handleLogout = () => {
        setAccessToken("");
        setRefreshToken("");
        setUserInfo("");
        setEmailInfo("")
        setDataInfo(undefined)
    }

    window.addEventListener('click', (e) => {
        if (e.target !== menuRef.current && e.target !== imgRef.current) {
            setOpen(false)
        }
    })


    return (
        <div className='bg-bg_hover  py-3 px-4 border-b h-[56px]  border-black flex items-center justify-between '>
            <div>
                {dataBodySideMenu.filter(item => item.path === path).map((item, index) => (
                    <p key={index} className='text-text h-6 text-sm font-main'>{item.title}</p>
                ))}

            </div>

            <div className='flex justify-end'>
                {dataInfo?.id && <div className='w-8 h-8 relative cursor-pointer z-20'  >
                    <img className={`rounded-full absolute z-50 `} ref={imgRef} src={dataInfo.avatar || avartar} onClick={() => setOpen(!open)} alt="" />
                    {open && (
                        <div className='w-[35px] h-[35px] absolute bg-bg_button rounded-full z-10 -top-[1.5px] -left-[1.6px] '>
                        </div>
                    )}
                </div>}
                <div className='mx-2 relative hover-trigger'>
                    <ButtonFeedback
                        statusFeed={statusFeed}
                        handleClickButton={handleClickButton}
                        title='Feedback' bg='bg_button' border='borderGray' text='text' />
                    <span className='hover-target bg-bg_button  absolute top-12 -right-1/2 px-2  text-xs text-white font-medium p-1 rounded-xs'>{getEmailLogin()}</span>

                </div>

                {dataInfo?.plan?.id !== 3 && <div className='min-w-[102px]' onClick={handleClickUp}>
                    <ButtonUpgrade
                        statusUpgrade={statusUpgrade}
                        handleClickButtonUpgrade={handleClickButtonUpgrade}
                        onClick={handleClickUp} title='Upgrade Plan' bg='red' border='red' text='white' />
                </div>}

            </div>
            <ul ref={menuRef}
                className={`${open ? 'visible opacity-100 -translate-y-0 translate-x-0 scale-100 z-100' : 'invisible opacity-0 -translate-y-12 translate-x-12 scale-0 '} 
                duration-150 ease-linear bg-gray_body w-[190px]  rounded-sm border text-text text-sm border-bg_button p-2 absolute right-[14.5rem] top-[52px] font-main shadow-xl z-50 `}>
                <li className='px-2 flex items-center h-9 hover:bg-hover_logout cursor-pointer '
                    onClick={handleClickAccount}>
                    <span className='mr-2 '>{icon_setting}</span>
                    Account Settings
                </li>
                <li className='px-2 flex items-center h-9 hover:bg-hover_logout cursor-pointer ' onClick={handleLogout}>
                    <span className='mr-2 '>{icon_logout}</span>
                    Sign Out
                </li>
            </ul>
        </div>
    )
}

export default HeaderLogin