import {icon_home, icon_setting} from './dataIcon';
import code_write from '../assets/icons/code-writer.svg';
import code_explainer from '../assets/icons/code-explainer.svg';
import code_changer from '../assets/icons/code-changer.svg';
import code_debugger from '../assets/icons/code-debugger.svg';
import regex_generator from '../assets/icons/regex-generator.svg';
import code_translator from '../assets/icons/code-translator.svg';
import code_optimizer from '../assets/icons/code-optimizer.svg';
import code_comparer from '../assets/icons/code-comparer.svg';
import topic_explainer from '../assets/icons/topic-explainer.svg';
import code_linter from '../assets/icons/code-linter.svg';
import code_suggester from '../assets/icons/code-suggester.svg';
import syntax_checker from '../assets/icons/syntax-checker.svg';
import code_learner from '../assets/icons/code-learner.svg';
import code_refactoring from '../assets/icons/code-refactoring.svg';
import variable_renamer from '../assets/icons/variable-renamer.svg';
import database_query from '../assets/icons/database-query.svg';
import code_commenter from '../assets/icons/code-commenter.svg';
import code_documenter from '../assets/icons/code-documenter.svg';
import test_generator from '../assets/icons/test-generator.svg';
import json_generator from '../assets/icons/json-generator.svg';


export const dataTopSideMenu = [
    {
        title:'Dashboard',
        icon:icon_home,
        status:true,
        path:'/'
    },
    {
        title:'Your Plan',
        icon:icon_setting,
        path:'',
        status:false,
    },
]

export const dataBodySideMenu = [
    {
        title:'Code Writer',
        path:'code-writer',
        icon: code_write
    },
    {
        title:'Code Explainer',
        path:'code-explainer', 
        icon: code_explainer,
    },
    {
        title:'Code Changer',
        path:'code-changer',
        icon: code_changer
    },
    {
        title:'Code Debugger',
        path:'code-debugger',
        icon: code_debugger,
    },
    {
        title:'Regex Generator',
        path:'regex-generator',
        icon: regex_generator,
    },
    {
        title:'Code Translator',
        path:'code-translator',
        icon: code_translator,
    },
    {
        title:'Code Optimizer',
        path:'code-optimizer',
        icon: code_optimizer,
    },
    {
        title:'Code Comparer',
        path:'code-comparer',
        icon: code_comparer,
    },
    {
        title:'Topic Explainer',
        path:'topic-explainer',
        icon: topic_explainer,
    },
    {
        title:'Code Linter',
        path:'code-linter',
        icon: code_linter,
    },
    {
        title:'Code Suggester',
        path:'code-suggester',
        icon: code_suggester, 
    },
    {
        title:'Syntax Checker',
        path:'syntax-checker',
        icon: syntax_checker
    },
    {
        title:'Code Learner',
        path:'code-learner',
        icon: code_learner
    },
    {
        title:'Code Refactoring',
        path:'code-refactoring',
        icon: code_refactoring
    },
    {
        title:'Variable Renamer',
        path:'variable-renamer',
        icon: variable_renamer
    },
    {
        title:'Database Query',
        path:'database-query',
        icon: database_query
    },
    {
        title:'Code Commenter',
        path:'code-commenter',
        icon: code_commenter
    },
    {
        title:'Code Documenter',
        path:'code-documenter',
        icon: code_documenter
    },
    {
        title:'Test Generator',
        path:'test-generator',
        icon: test_generator
    },
    {
        title:'JSON Genertor',
        path:'json-generator', 
        icon: json_generator
    },
    
]