import React from 'react'
import ToolComponents from './ToolsComponent'
import { ToolConfig } from './ToolConfig';



const CodeWrite = () => {
    return (
        <ToolComponents toolConfig={ToolConfig.codeWriter}/>
    )
}

export default CodeWrite
