import React, { useContext } from 'react';
import { icon_close, } from '../mocks/dataIcon';
import { dataShareQuery } from '../mocks/dataShareQuery';
import MainContext from '../pages/MainContext';

const ShareQuery = () => {
    const { queryString, handleClickClose } = useContext(MainContext)
    const [copyOk, setCopyOk] = React.useState(false);

    const queryUrl = window.location.origin + '/q/' + queryString

    const handleCopy = () => {
        navigator.clipboard.writeText(queryUrl);
        alert("Copied Done");
        setCopyOk(true);
    }

    return (
        <div className='text-white bg-grayForm border border-colorBorder rounded-xs h-[240px] w-[534px] overflow-hidden '>
            <div className='flex items-center justify-between border-b border-colorBorder bg-bg_hover  px-4'>
                <h1 className=' text-text text-lg font-semibold font-main h-[56px] leading-[56px] '>Share Query</h1>
                <span className='p-2 cursor-pointer hover:bg-[#2A2827] duration-300 ease-linear rounded-sm'
                    onClick={handleClickClose}>{icon_close}
                </span>
            </div>
            <div className='py-6 '>
                <p className='px-6 text-des font-main h-4 mb-2'>
                    {queryUrl}
                </p>
                <div className=' overflow-x-scroll '>
                    <div className=' w-[576px]  px-6 flex  items-center gap-3 '>
                        {dataShareQuery.map((item, index) => {
                            return (
                                <div key={index}
                                    style={{ backgroundColor: `${item.backgroundColor}` }}
                                    className={`h-24 !w-24 rounded-md flex items-center justify-center `}>
                                    {
                                        index === 0 ? <a href="#" onClick={handleCopy} target='' className=' flex flex-col gap-2 w-full h-full items-center justify-center'>
                                            <span>{item.icon}</span>
                                            <span className='font-bold font-main' style={{ color: `${item.color}` }}>{item.title}</span>
                                        </a> : <a href={item.link} target='' className='flex flex-col gap-2 w-full h-full items-center justify-center'>
                                            <span>{item.icon}</span>
                                            <span className='font-bold font-main' style={{ color: `${item.color}` }}>{item.title}</span>
                                        </a>
                                    }


                                </div>
                            )
                        })}

                    </div>
                </div>
            </div>

        </div>
    )
}

export default ShareQuery