import code_writer from '../../assets/icons/code-writer.svg';
import code_explainer from '../../assets/icons/code-explainer.svg';
import code_changer from '../../assets/icons/code-changer.svg';
import code_debugger from '../../assets/icons/code-debugger.svg';
import regex_generator from '../../assets/icons/regex-generator.svg';
import code_translator from '../../assets/icons/code-translator.svg';
import code_optimizer from '../../assets/icons/code-optimizer.svg';
import code_comparer from '../../assets/icons/code-comparer.svg';
import topic_explainer from '../../assets/icons/topic-explainer.svg';
import code_linter from '../../assets/icons/code-linter.svg';
import code_suggester from '../../assets/icons/code-suggester.svg';
import syntax_checker from '../../assets/icons/syntax-checker.svg';
import code_learner from '../../assets/icons/code-learner.svg';
import code_refactoring from '../../assets/icons/code-refactoring.svg';
import variable_renamer from '../../assets/icons/variable-renamer.svg';
import database_query from '../../assets/icons/database-query.svg';
import code_commenter from '../../assets/icons/code-commenter.svg';
import code_documenter from '../../assets/icons/code-documenter.svg';
import test_generator from '../../assets/icons/test-generator.svg';
import json_generator from '../../assets/icons/json-generator.svg';



export const ToolConfig = {
    codeWriter: {
        'promptType':'codeWriter',
        'icon': code_writer,
        'url':'/tools/code-writer',
        'inputs': [
            {
                title: 'What do you want to do?',
                require: true,
                des: 'Explain what you want to do below and codefy.ai will suggest some code for you! ',
                inputType: 'text',
                place1: 'What do you want to do?',
                place2: 'What language should it be in?',
                place3: 'You can also copy and paste a spec...'
            },
            {
                title: 'What context is there?',
                optional: true,
                des: '',
                inputType: 'text',
                place1: 'Any particular method or strategy?',
                place2: 'Additional context to your code?',
                place3: ''
            }
        ]
    },
    CodeExplainer: {
        'promptType':'CodeExplainer',
        'icon': code_explainer,
        'url':'/tools/code-explainer',
        'inputs': [
            {
                title: 'Your code',
                require: true,
                des: 'Write or paste your code in the box below and codefy.ai will explain it for you!',
                inputType: 'code',
                place1: 'Khóc làm gì',
                place2: '',
                place3: ''
            },
            {
                title: 'Any additional context?',
                optional: true,
                des: '',
                inputType: 'text',
                place1: 'Additional context to your code?',
                place2: 'Any packages being used?',
                place3: ''
            }
        ]
    },
    codeChanger: {
        'promptType':'codeChanger',
        'icon': code_changer,
        'url':'/tools/code-changer',
        'inputs': [
            {
                title: 'Your code',
                require: true,
                des: 'Write or paste the code you want to change below',
                inputType: 'code',
                place1: 'Khóc làm gì',
                place2: '',
                place3: ''
            },
            {
                title: 'How do you want to change the code?',
                optional: true,
                des: '',
                inputType: 'text',
                place1: 'Change the buttons',
                place2: 'Use fetch insted of axios',
                place3: 'Make the code more concise'
            }
        ]
    },
    codeDebugger: {
        'promptType':'codeDebugger',
        'icon': code_debugger,
        'url':'/tools/code-debugger',
        'inputs': [
            {
                title: 'Your code',
                require: true,
                des: 'Write or paste your code in the box below and codefy.ai will debug it for you!',
                inputType: 'code',
                place1: 'Khóc làm gì',
                place2: '',
                place3: ''
            },
            {
                title: 'Error Message',
                optional: true,
                des: 'Add any extra information that could help codefy.ai debug your code',
                inputType: 'text',
                place1: 'Uncaught ReferenceError: foo is not defined',
                place2: '',
                place3: ''
            }
        ]
    },
    RegexGenerator: {
        'promptType':'RegexGenerator',
        'icon': regex_generator,
        'url':'/tools/regex-generator',
        'inputs': [
            {
                title: 'Regex Specification',
                optional: false,
                des: 'Explain the regex you want to build below and codefy.ai will create it!',
                inputType: 'text',
                place1: 'Match numbers with decimal points...',
                place2: 'Accept strings that are valid emails',
                place3: ''
            }
        ]
    },
    CodeTranslator: {
        'promptType':'CodeTranslator',
        'icon': code_translator,
        'url':'/tools/code-translator',
        'inputs': [
            {
                title: 'Your code',
                require: true,
                des: 'Write or paste the code you want to translate below!',
                inputType: 'code',
                place1: 'Khóc làm gì',
                place2: '',
                place3: ''
            },
            {
                title: 'How do you want to translate?',
                optional: false,
                des: 'Explain how you want codefy.ai to translate your code.',
                inputType: 'text',
                place1: 'Translate into Python...',
                place2: 'Translate to use a different library..',
                place3: ''
            }
        ]
    },
    CodeOptimizer: {
        'promptType':'CodeOptimizer',
        'icon': code_optimizer,
        'url':'/tools/code-optimizer',
        'inputs': [
            {
                title: 'Your code',
                require: true,
                des: 'Paste your code below and codefy.ai will suggest optimizations to improve performance!',
                inputType: 'code',
            },
            {
                title: 'Any additional context?',
                optional: true,
                des: '',
                inputType: 'text',
                place1: 'Additional context to your code?',
                place2: 'Any packages being used?',
                place3: ''
            }
        ]
    },
    CodeCompare: {
        'promptType':'CodeCompare',
        'icon': code_comparer,
        'url':'/tools/code-comparer',
        'inputs': [
            {
                title: 'Code Block 1',
                require: true,
                des: 'Write or paste the first block of code you want to compare!',
                inputType: 'code',
            },
            {
                title: 'Code Block 2',
                require: true,
                des: 'Write or paste the second block of code you want to compare!',
                inputType: 'code1',
            },
            {
                title: 'Any additional context?',
                optional: true,
                des: '',
                inputType: 'text',
                place1: 'How do you want to compare the code?',
                place2: '',
                place3: ''
            }
        ]
    },
    TopicExplainer: {
        'promptType':'TopicExplainer',
        'icon': topic_explainer,
        'url':'/tools/topic-explainer',
        'inputs': [
            {
                title: 'What do you want explained?',
                optional: false,
                des: 'Write any topic and codefy.ai will explain it for you!',
                inputType: 'text',
                place1: 'JavaScript fetch API',
                place2: '',
                place3: ''
            },
            {
                title: 'How do you want it explained?',
                optional: true,
                des: '',
                inputType: 'text',
                place1: 'Explain simply...',
                place2: 'Use examples...',
                place3: 'I have done xyz already...'
            }
        ]
    },
    CodeLinter: {
        'promptType':'CodeLinter',
        'icon': code_linter,
        'url':'/tools/code-linter',
        'inputs': [
            {
                title: 'Your code',
                require: true,
                des: 'Write or paste code you want to analyze and improve below!',
                inputType: 'code',
            },
            {
                title: 'How do you want it improved?',
                optional: true,
                des: '',
                inputType: 'text',
                place1: 'Any specific programming errors to check for?',
                place2: 'Any specific styling errors to check for?',
                place3: ''
            }
        ]
    },
    CodeSuggester: {
        'promptType':'CodeSuggester',
        'icon': code_suggester,
        'url':'/tools/code-suggester',
        'inputs': [
            {
                title: 'Your code',
                require: true,
                des: 'Type in your code and codefy.ai will suggest the next few lines of code for you.',
                inputType: 'code',
            },
            {
                title: 'Any additional context?',
                optional: true,
                des: '',
                inputType: 'text',
                place1: 'What do you want the new code to accomplish?',
                place2: '',
                place3: ''
            }
        ]
    },
    SyntaxChecker: {
        'promptType':'SyntaxChecker',
        'icon': syntax_checker,
        'url':'/tools/syntax-checker',
        'inputs': [
            {
                title: 'Code Input',
                require: true,
                des: 'Type or paste your code below and codefy.ai will check for syntax errors.',
                inputType: 'code',
            },
            {
                title: 'What do you want to check for?',
                optional: true,
                des: '',
                inputType: 'text',
                place1: 'Any specific syntax errors to check for?',
                place2: '',
                place3: ''
            }
        ]
    },
    CodeLearner: {
        'promptType':'CodeLearner',
        'icon': code_learner,
        'url':'/tools/code-learner',
        'inputs': [
            {
                title: 'Question',
                optional: false,
                des: 'Ask codefy.ai any coding-related question and get a detailed answer with code examples.',
                inputType: 'text',
                place1: 'What is the difference between a for and a while loop?',
                place2: 'How is dynamic programming useful?',
                place3: ''
            },
            {
                title: 'How do you want it explained?',
                optional: false,
                des: '',
                inputType: 'text',
                place1: "Explain like I'm 5...",
                place2: 'Use only examples...',
                place3: ''
            },
        ]
    },
    CodeRefactoring: {
        'promptType':'CodeRefactoring',
        'icon': code_refactoring,
        'url':'/tools/code-refactoring',
        'inputs': [
            {
                title: 'Your Code',
                require: true,
                des: 'Paste or upload your code below and codefy.ai will suggest ways to improve readability and maintainability.',
                inputType: 'code',
            },
            {
                title: 'How do you want to refactor?',
                optional: true,
                des: '',
                inputType: 'text',
                place1: 'Add more spacing to this code...',
                place2: 'Clean up the format...',
                place3: ''
            }
        ]
        

    },
    VariableRenamer: {
        'promptType':'VariableRenamer',
        'icon': variable_renamer,
        'url':'/tools/variable-renamer',
        'inputs': [
            {
                title: 'Your Code',
                require: true,
                des: 'Paste or upload your code below and codefy.ai will suggest better variable names for improved readability.',
                inputType: 'code',
            },
            {
                title: 'How do you want variables to be named?',
                optional: true,
                des: '',
                inputType: 'text',
                place1: 'Use longer variable names...',
                place2: 'Change names to be camel case...',
                place3: ''
            }
        ]
    },
    DatabaseQuery: {
        'promptType':'DatabaseQuery',
        'icon': database_query,
        'url':'/tools/database-query',
        'inputs': [
            {
                title: 'Question',
                optional: false,
                des: 'Ask codefy.ai how to make a database query and it will write it for you.',
                inputType: 'text',
                place1: 'Get the user table in SQL...',
                place2: 'Select all SQL users named John with a subscription...',
                place3: ''
            },
            {
                title: 'Any additional context?',
                optional: true,
                des: '',
                inputType: 'text',
                place1: 'I am using xyz database...',
                place2: '',
                place3: ''
            },
        ]
    },
    CodeCommenter: {
        'promptType':'CodeCommenter',
        'icon': code_commenter,
        'url':'/tools/code-commenter',
        'inputs': [
            {
                title: 'Your Code',
                require: true,
                des: 'Paste or write your code below and codefy.ai will write comments for it!',
                inputType: 'code',
            },
            {
                title: 'Comment Style',
                optional: true,
                des: '',
                inputType: 'text',
                place1: ' Write multiline comments...',
                place2: 'Write comments for each function...',
                place3: 'Write comments for each variable...'
            }
        ]
    },
    CodeDocumenter: {
        'promptType':'CodeDocumenter',
        'icon': code_documenter,
        'url':'/tools/code-documenter',
        'inputs': [
            {
                title: 'Your Code',
                require: true,
                des: 'Paste or write your code below and codefy.ai will create documentation for it!',
                inputType: 'code',
            },
            {
                title: 'Documentation Style',
                optional: true,
                des: '',
                inputType: 'text',
                place1: 'Document with inline comments...',
                place2: 'Write a specification...',
                place3: 'Write the documentation as a report...'
            }
        ]
    },
    TestGenerator: {
        'promptType':'TestGenerator',
        'icon': test_generator,
        'url':'/tools/test-generator',
        'inputs': [
            {
                title: 'Your Code',
                require: true,
                des: 'Paste or write your code below and codefy.ai will generate tests for it!',
                inputType: 'code',
            },
            {
                title: 'Testing Context',
                optional: false,
                des: 'What should the tests test for?',
                inputType: 'text',
                place1: 'This function is supposed to return a number...',
                place2: 'Test that all functions run without errors...',
                place3: 'Make sure that no variables are null...'
            }
        ]
    },
    JSONGenerator: {
        'promptType':'JSONGenerator',
        'icon': json_generator,
        'url':'/tools/json-generator',
        'inputs': [
            {
                title: 'Your Data',
                optional: false,
                des: 'Paste or write the data you want to turn into a JSON below.',
                inputType: 'text',
                place1: 'Apples sold: 5',
                place2: 'Bananas sold: 3',
                place3: 'Oranges sold: 6'
            }
        ]
    },
}







