import { ButtonUp } from "../components/ButtonRed";


export const dataUpgrade = [
    {
        id: 2,
        topName: 'Most Popular!',
        header: 'Basic',
        icon:'',
        text: <p className="text-[36px] h-12 font-main font-semibold text-text">$9<span className="text-base text-grayButton">/mo</span></p>,
        text_span: <span>(2 mornings of coffee)</span>,
        line1: <span className="text-des"><b>500k </b> tokens/month</span>,
        line2: <span className="text-des"><b>~100k</b> lines of code processed/month</span>,
        line3: <span className="text-des"><b>4,000 </b> token input limit</span>,
        line4: <span className="text-des"><b>Standard </b> access to new features</span>,
        
    },
    {
        id: 3,
        header: 'Pro',
        icon: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"><path fill="#F30" d="M21.71 11.29 19.36 9V5.64a1 1 0 0 0-1-1h-3.31l-2.34-2.35a.999.999 0 0 0-1.42 0L9 4.64H5.64a1 1 0 0 0-1 1V9l-2.35 2.29a1 1 0 0 0 0 1.42l2.35 2.34v3.31a1 1 0 0 0 1 1H9l2.34 2.35a1.001 1.001 0 0 0 1.42 0l2.34-2.35h3.31a1 1 0 0 0 1-1v-3.31l2.35-2.34a1 1 0 0 0-.05-1.42Zm-4.05 2.64a1.002 1.002 0 0 0-.3.71v2.72h-2.72a.998.998 0 0 0-.71.3L12 19.59l-1.93-1.93a1 1 0 0 0-.71-.3H6.64v-2.72a1 1 0 0 0-.3-.71L4.41 12l1.93-1.93a1 1 0 0 0 .3-.71V6.64h2.72a1 1 0 0 0 .71-.3L12 4.41l1.93 1.93a1 1 0 0 0 .71.3h2.72v2.72a1.001 1.001 0 0 0 .3.71L19.59 12l-1.93 1.93Z"></path><path fill="#F30" fill-rule="evenodd" d="m6 15-3-3 3-3V6h3l3-3 3 3h3v3l3 3-3 3v3h-3l-3 3-3-3H6v-3Zm4.21-.25a.999.999 0 0 0 1.42 0l4.08-4.08a1.004 1.004 0 1 0-1.42-1.42l-3.37 3.38-1.21-1.22a1.004 1.004 0 0 0-1.42 1.42l1.92 1.92Z" clip-rule="evenodd"></path><path fill="#E0EFE0" d="M10.535 14.969a.998.998 0 0 1-.325-.219l-1.92-1.92a1.004 1.004 0 0 1 1.42-1.42l1.21 1.22 3.37-3.38a1.004 1.004 0 1 1 1.42 1.42l-4.08 4.08a.997.997 0 0 1-1.095.219Z"></path></svg>,
        text: <p className="text-[36px] h-12 font-main font-semibold text-text">$19<span className="text-base text-grayButton">/mo</span></p>,
        line1: <span className="text-des"><b>Unlimited</b> tokens/month*</span>,
        line2: <span className="text-des whitespace-pre md:whitespace-normal"><b>Unlimited</b> lines of code processed/month*</span>,
        line3: <span className="text-des"><b>8,000 </b> token input limit</span>,
        line4: <span className="text-des"><b>Priority </b> access to new features</span>,
        
    }
]