import React, { useContext } from 'react';
import { icon_close, } from '../mocks/dataIcon';
import { ButtonComponent } from './ButtonRed';
import { getEmailLogin, getNameLogin } from '../services/helper/TokenHelper';
import avartar from '../assets/icons/icon_user.png'
import Circle from 'react-circle';
import moment from 'moment/moment';
import MainContext from '../pages/MainContext';


const AccountSetting = (props) => {
    const { dataInfo, handleClickClose, handleClickUp } = useContext(MainContext);

    const dataFilter = {
        totalToken: 0,
        plan: dataInfo?.plan,
        ...dataInfo?.userTrackings.find(item => item?.plan?.id === dataInfo?.plan.id)
    }


    return (
        <div className='text-white bg-grayForm border border-colorBorder rounded-md h-[312px] w-[640px] overflow-hidden'>
            <div className='flex items-center justify-between border-b border-colorBorder bg-bg_hover  px-4'>
                <h1 className=' text-text text-lg font-semibold font-main h-[56px] leading-[56px] '>Account Settings</h1>
                <span className='p-2 cursor-pointer hover:bg-[#2A2827] duration-300 ease-linear rounded-sm'
                    onClick={handleClickClose}>{icon_close}
                </span>
            </div>
            <div className=' '>
                <div className='px-6 pt-6 flex gap-6 border-b border-colorBorder  h-[120px]'>
                    <div className='' >
                        <img className='rounded-full w-16 h-16' src={dataInfo.avatar || avartar} alt="avatar" />
                    </div>
                    <div>
                        <h1 className='mb-2 text-lg text-text font-semibold'>{getNameLogin() ? getNameLogin() : 'No name'}</h1>
                        <p className='text-sm text-des'>{getEmailLogin() ? getEmailLogin() : 'no email'}</p>
                    </div>
                </div>
                <div className='px-6 pt-6 flex justify-between items-start  h-[136px]'>
                    <div className='flex'>

                        <div className='w-[80px] h-[80px]'>
                            <Circle
                                animate={true} // Boolean: Animated/Static progress
                                animationDuration="1s" //String: Length of animation
                                responsive={true} // Boolean: Make SVG adapt to parent size
                                size={30} // Number: Defines the size of the circle.
                                lineWidth={40} // Number: Defines the thickness of the circle's stroke.
                                progress={(dataFilter?.totalToken / dataFilter?.plan?.tokensPerMonth) * 100} // Number: Update to change the progress and percentage.
                                progressColor={dataFilter?.totalToken >= dataFilter?.plan?.tokensPerMonth - 1000 ? "red" : "whitesmoke"}  // String: Color of "progress" portion of circle.
                                bgColor="#403E3D" // String: Color of "empty" portion of circle.
                                textColor="hotpink" // String: Color of percentage text color.
                                textStyle={{
                                    font: 'bold 5rem Helvetica, Arial, sans-serif' // CSSProperties: Custom styling for percentage.
                                }}
                                percentSpacing={10} // Number: Adjust spacing of "%" symbol and number.
                                roundedStroke={true} // Boolean: Rounded/Flat line ends
                                showPercentage={false} // Boolean: Show/hide percentage.
                                showPercentageSymbol={true} // Boolean: Show/hide only the "%" symbol.
                            />
                        </div>

                        <div className='ml-5'>
                            <h1 className='mb-2 text-lg text-text font-semibold'>{dataFilter?.plan?.name}</h1>
                            <h1 className=' text-3xl text-text font-semibold'>{dataFilter?.plan?.name === "Pro" ? "Unlimited" :
                                <>
                                    <span>{dataFilter?.totalToken?.toString().replace(/(?:(^\d{1,3})(?=(?:\d{3})*$)|(\d{3}))(?!$)/mg, '$1$2.')}</span>
                                    /
                                    <span className='text-base text-grayButton'>{dataFilter?.plan?.tokensPerMonth?.toString().replace(/(?:(^\d{1,3})(?=(?:\d{3})*$)|(\d{3}))(?!$)/mg, '$1$2.')}</span></>}</h1>
                            <p className='text-sm text-des'>tokens used ( resets {moment(dataFilter?.resetAt).add(1, 'M').format('MMMM Do')})</p>
                        </div>
                    </div>
                    <div className='flex items-center'>
                        <a href="https://billing.stripe.com/p/login/3cs8wzdlqg7y4kEbII" target='_blank'>
                            <ButtonComponent title='Manage Billing' bg='bg_button' border='borderGray' text='text' color='borderGray' />
                        </a>
                        {dataInfo?.plan?.id !== 3 && <div onClick={handleClickUp}>
                            <ButtonComponent onClick={handleClickUp} title='Upgrade Plan' bg='red' border='red' text='white' color='bg_red' />
                        </div>}
                    </div>
                </div>
            </div>

        </div>
    )
}

export default AccountSetting