import apiClient from '../services/ApiClient';
import { setAccessToken, setRefreshToken, getRefreshToken, getAccessToken } from '../services/helper/TokenHelper';


// users function
export const loginEmail = async (params) => {
	return await apiClient.post('v1/auth/email/login', params);
};

export const loginGoogle = async (params) => {
	return await apiClient.post('v1/auth/google/login', params);
};

export const getVerify = async (params) => {
	return await apiClient.get(`v1/auth/email/verify?token=${params}`)
};

export const chatGpts = async (params) => {
	return await apiClient.post('v1/gpt/chat', params)
}

export const InfoUser = async () => {
	return await apiClient.get('v1/auth/me',{
		headers:{'Authorization': `Bearer ${getAccessToken()}`}
	})
}

export const userRefreshToken = async () => {
	try {
		const refreshToken = getRefreshToken();
		const res = await apiClient.post('v1/users/refresh-token', { refreshToken });
		const accessToken = res.data.token;
		if (accessToken !== getAccessToken()) {
			setAccessToken(accessToken);
			window.location.reload();
		}
	} catch (error) {
		setAccessToken('');
		setRefreshToken('');
		// window.location.reload();
	}
};

export const userResetPassword = async (params) => {
	return await apiClient.post('v1/send-email-forgot-pass', params);
};

export const userAddPoint = async (params) => {
	return await apiClient.post('v1/admin/adminAddPoint', params);
};

//  payments

export const payMents = async (params) => {
	return await apiClient.post('/payments', params);
};
export const payMentsPaypal = async (params) => {
	return await apiClient.post('/payments/paypal', params);
};
