import React, { useState, useContext } from 'react';
import { DataSricing } from '../mocks/dataSricing.js';
import CollapseList from '../components/CollapseList';
import LogginForm from '../components/LogginForm';
import Header from '../components/Header/Header'
import Footer from '../components/Footer/Footer';
import MainContext from './MainContext.jsx';


const Pricing = () => {
    

    const { hanldeClickShowFormLogin} = useContext(MainContext)
    return (
        <div className=' relative bg-bg_black'>
            <div className='min-h-[1024px]  bg_pricing price'>
                <Header />
                <h1 className='text-[32px] text-center text-main font-bold text-white font-main'>Tools from the future.<br />
                    With early adopter pricing.
                </h1>
                <p className='px-4 mt-8 text-center text-text font-main'>
                    <a href="mailto:contact@codefy.ai?subject=codefy.ai%20bulk%20inquiry"
                        className='hover:underline-offset-1 duration-300 ease-linear text-text'>
                        Contact us regarding our discounted enterprise plans! →
                    </a>
                </p>
                <div className='mt-16'>
                    <div className='flex md:flex-col md:items-center gap-6 justify-center items-end pb-16'>{
                        DataSricing.map((item, index) => {
                            return (
                                <div key={index} className='w-[336px] font-main '>
                                    <div className={`${item.topName ? 'text-text font-semibold bg-gradient-to-r from-orange via-middle to-pink p-4 border text-center text-base border-grayText border-b-[0px] rounded-t-lg overflow-hidden' : ''}`}>{item.topName ? item.topName : ""}</div>
                                    <div className={`${item.topName ? '' : 'rounded-t-lg'}  border border-grayText rounded-b-lg overflow-hidden`}>
                                        <div className='p-12 h-[204px] bg-black_header font-bold ' >
                                            <h1 className='text-2xl text-text'>{item.header}</h1>
                                            <h1 className='text-5xl text-text'>{item.text}</h1>
                                            <span className='text-text'>{item.text_span ? item.text_span : ''}</span>
                                        </div>
                                        <div className='p-9 bg-gray_body font-iter h-[334px] flex gap-3 flex-col'>
                                            <div className='flex items-start  text-text leading-6'>
                                                <span className='mr-2 '>{item.icon}</span>
                                                {item.line1}
                                            </div>
                                            <div className='flex items-start  text-text leading-6'>
                                                <span className='mr-2 '>{item.icon}</span>
                                                {item.line2}
                                            </div>
                                            <div className='flex items-start  text-text leading-6'>
                                                <span className='mr-2 '>{item.icon}</span>
                                                {item.line3}
                                            </div>
                                            <div className='flex items-start text-text leading-6'>
                                                <span className='mr-2 '>{item.icon}</span>
                                                {item.line4}
                                            </div>
                                            <div className='text-text cursor-pointer mt-auto ' onClick={hanldeClickShowFormLogin}>
                                                <div className='flex items-center text-white font-semibold font-main  justify-center bg-red h-12 rounded-sm'>
                                                    Get started
                                                    <svg class="h-5 w-5 text-red-500 ml-2" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">  <path stroke="none" d="M0 0h24v24H0z" />  <line x1="5" y1="12" x2="19" y2="12" />  <line x1="15" y1="16" x2="19" y2="12" />  <line x1="15" y1="8" x2="19" y2="12" /></svg>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}</div>
                </div>

            </div>
            <div className='bg-bg_black pt-24 mx-auto flex justify-center flex-col items-center'>
                <div className='w-[45rem] sm:w-full px-4 pb-24 mx-auto'>
                    <CollapseList />
                </div>
                <Footer />
            </div>
            
            



        </div>
    )
}

export default Pricing