import React, { useState, useContext } from 'react'
import { NavLink, } from 'react-router-dom';
import logo from '../../assets/images/logo.svg';
import { NavMenu } from '../../mocks/dataHeader.js';
import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import MainContext from '../../pages/MainContext';

const Header = () => {
const {isOpenMenu, handleClickIconMenu} = useContext(MainContext)



  return (
    <div className='h-[9rem] w-full flex items-center justify-between bg-transparent  px-[16rem] lg:px-16 sm:px-8 z-10 relative xs:bg-bg_black'>
      <div className=''>
        <NavLink to='/'>
          <img className='h-[26px] w-[135px] mb-1' src={logo} alt="logo" />

        </NavLink>
      </div>
      <div className='flex items-center text-white font-main gap-8 sm:hidden'>
        {NavMenu.map((item, index) => {
          return (
            <div key={index}>
              <NavLink to={item.path} target={item.target ? item.target : ''}>
                <div className="hover:decoration-1 hover:underline duration-300 ease-in">
                  <span className='text-sm font-medium '>{item.name}</span>
                </div>
              </NavLink>
            </div>
          )
        })}
      </div>
      <div onClick={handleClickIconMenu} className='sm:block hidden p-2 hover:opacity-90 cursor-pointer duration-200 ease-linear relative'>
        {isOpenMenu ?
          <MenuOpenIcon className='text-text' />
          :
          <MenuIcon className='text-text' />
        }
    
      </div>

    </div>
  )
}

export default Header
