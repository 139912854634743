import React, { useState, useContext } from 'react';
import logo from '../../assets/images/logo_login.svg'
import { dataBodySideMenu } from '../../mocks/dataSideBar.js'
import { icon_box, icon_top } from '../../mocks/dataIcon';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';

import Circle from 'react-circle';
import MainContext from '../../pages/MainContext';
import { getAccessToken } from '../../services/helper/TokenHelper';

const SideBar = (props) => {
    const { pathname, } = useLocation();
    const navigate = useNavigate();
    const { dataInfo, handleClickAccount } = useContext(MainContext)


    const [showMenu, setShowMenu] = useState(true);

    const handleClickShowMenu = () => {
        setShowMenu(!showMenu)
    }
    const handleClickLogo = () =>{
        if(!getAccessToken())
        navigate('/login')
    }

    const dataFilter = {
        totalToken: 0,
        plan: dataInfo?.plan,
        ...dataInfo?.userTrackings.find(item => item?.plan?.id === dataInfo.plan.id)
    }


    return (
        <div className='bg-bg_black min-h-screen  overflow-scroll p-9'>
            <div className="">
                <img className='mb-10 cursor-pointer' src={logo} alt="" onClick={handleClickLogo} />
                <div className='pb-5 border-b-2 border-bg_hover'>
                    <div className={`flex items-center p-4 h-12 ${ pathname === "/" ? 'bg-gray_body' : ''}  hover:bg-bg_hover cursor-pointer duration-200 ease-in rounded-sm`}
                        onClick={() => navigate('/')} >
                        <span className='mr-2'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"><path d="m13.333 5.333-4-3.506a2 2 0 0 0-2.666 0l-4 3.506A2 2 0 0 0 2 6.84v5.827a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V6.833a1.999 1.999 0 0 0-.667-1.5Zm-4 8H6.667V10a.667.667 0 0 1 .666-.667h1.334a.667.667 0 0 1 .666.667v3.333Zm3.334-.666a.667.667 0 0 1-.667.666h-1.333V10a2 2 0 0 0-2-2H7.333a2 2 0 0 0-2 2v3.333H4a.667.667 0 0 1-.667-.666V6.833a.667.667 0 0 1 .227-.5l4-3.5a.667.667 0 0 1 .88 0l4 3.5a.667.667 0 0 1 .227.5v5.834Z"></path><path d="M3.123 6v7.5h3V9h4v4.5h3V6l-5-4-5 4Z"></path></svg></span>
                        <span className='font-main'> Dashboard</span>
                    </div>
                    {dataInfo?.id &&

                        <div className='flex items-center p-4 h-12 hover:bg-bg_hover cursor-pointer duration-200 ease-in rounded-sm'
                            onClick={() => handleClickAccount()}
                        >
                            <div className='flex items-center justify-between w-full relative'>
                                <div className='flex items-center'>
                                    <span className='mr-2'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"><path d="M14.473 7.527 12.907 6V3.76a.667.667 0 0 0-.667-.667h-2.207l-1.56-1.566a.666.666 0 0 0-.946 0L6 3.093H3.76a.667.667 0 0 0-.667.667V6L1.527 7.527a.666.666 0 0 0 0 .946l1.566 1.56v2.207a.667.667 0 0 0 .667.667H6l1.56 1.566a.668.668 0 0 0 .947 0l1.56-1.566h2.206a.667.667 0 0 0 .667-.667v-2.207l1.567-1.56a.667.667 0 0 0-.034-.946Zm-2.7 1.76a.666.666 0 0 0-.2.473v1.813H9.76a.668.668 0 0 0-.473.2L8 13.06l-1.287-1.287a.666.666 0 0 0-.473-.2H4.427V9.76a.667.667 0 0 0-.2-.473L2.94 8l1.287-1.287a.667.667 0 0 0 .2-.473V4.427H6.24a.667.667 0 0 0 .473-.2L8 2.94l1.287 1.287a.667.667 0 0 0 .473.2h1.813V6.24a.668.668 0 0 0 .2.473L13.06 8l-1.287 1.287Z"></path></svg></span>
                                    <span className='font-main'> Your Plan</span>

                                </div>
                                <div className='w-4 h-4 group cursor-pointer' >
                                    <Circle
                                        animate={true} // Boolean: Animated/Static progress
                                        animationDuration="1s" //String: Length of animation
                                        responsive={true} // Boolean: Make SVG adapt to parent size
                                        size={30} // Number: Defines the size of the circle.
                                        lineWidth={40} // Number: Defines the thickness of the circle's stroke.
                                        progress={(dataFilter?.totalToken / dataFilter?.plan?.tokensPerMonth) * 100} // Number: Update to change the progress and percentage.
                                        progressColor={dataFilter?.totalToken >= dataFilter?.plan?.tokensPerMonth - 1000 && dataFilter?.plan?.name !== "Pro" ? "red" : dataFilter?.plan?.name === "Pro" ? "" : "whitesmoke"}  // String: Color of "progress" portion of circle.
                                        bgColor="#403E3D" // String: Color of "empty" portion of circle.
                                        textColor="hotpink" // String: Color of percentage text color.
                                        textStyle={{
                                            font: 'bold 5rem Helvetica, Arial, sans-serif' // CSSProperties: Custom styling for percentage.
                                        }}
                                        percentSpacing={10} // Number: Adjust spacing of "%" symbol and number.
                                        roundedStroke={true} // Boolean: Rounded/Flat line ends
                                        showPercentage={false} // Boolean: Show/hide percentage.
                                        showPercentageSymbol={true} // Boolean: Show/hide only the "%" symbol.
                                    />
                                    <div className=' scale-0  h-full bg-grayButton text-text text-center text-xs rounded-xs py-1 absolute z-10 
                                group-hover:scale-100 group-hover:visible  bottom-full -right-[30%] duration-200 ease-in-out top-8 px-2 pointer-events-none'>
                                        <span className='text-xs'>{dataFilter?.plan?.name === "Pro" ? "Unlimited" : `${dataFilter?.totalToken.toString().replace(/(?:(^\d{1,3})(?=(?:\d{3})*$)|(\d{3}))(?!$)/mg, '$1$2.')}/${dataFilter?.plan?.tokensPerMonth.toString().replace(/(?:(^\d{1,3})(?=(?:\d{3})*$)|(\d{3}))(?!$)/mg, '$1$2.')}`} tokens used</span>
                                        <div className='w-[10px] h-[10px] rotate-45 bg-grayButton absolute  right-[28%] -top-[20%]'>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <span>
                            </span>
                        </div>
                        }
                </div>
                <div className='flex shrink flex-col overflow-y-hidden h-[calc(100vh-18rem)]'>
                    <div className='flex items-center justify-between p-4 hover:bg-bg_hover cursor-pointer duration-200 ease-in rounded-sm'
                        onClick={handleClickShowMenu}>
                        <div className='flex items-center font-main '>
                            <span className='mr-2'>{icon_box}</span>
                            <span>Tools</span>
                        </div>
                        <span className={`${showMenu ? 'rotate-180' : 'rotate-0'} duration-300 ease-linear`}>{icon_top}</span>
                    </div>
                    {showMenu ?
                        <>
                            <div className='flex flex-col shrink '>
                                {dataBodySideMenu.map((item, index) => {
                                    return (
                                        <div key={index} className='!h-9 leading-9 ml-8 hover:bg-bg_hover cursor-pointer duration-200 ease-in rounded-sm font-main'>
                                            <NavLink to={`/tools/${item.path}`}
                                                className={` text-sm w-full text-white h-9 flex items-center px-4`}>{item.title}</NavLink>

                                        </div>
                                    )
                                })}
                            </div>
                        </>
                        :
                        <></>}

                </div>


            </div>

            <div></div>
        </div>
    )
}

export default SideBar