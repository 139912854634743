import React, { useEffect, useState, useContext, useRef } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import axios from "axios";

import {
  addDoc,
  getDocs,
  where,
  collection,
  serverTimestamp,
} from "firebase/firestore";
import { orderBy, query } from "firebase/firestore";

import YourCode from "../../components/YourCode";
import Optional from "../../components/Optional";
import Title from "../../components/Title";
import ResponsiveGenerate from "../../components/ResponsiveGenerate";
import NextConversation from "../../components/NextConversation";
import { getAccessToken, getToken } from "../../services/helper/TokenHelper";
import FormInfo from "../../components/FormInfo";
import ShareQuery from "../../components/ShareQuery.jsx";
import { ButtonRun } from "../../components/ButtonRed";
import { icon_play } from "../../mocks/dataIcon";
import { css } from "@emotion/css";
import ScrollToBottom from "react-scroll-to-bottom";
import MainContext from "../MainContext.jsx";
import { firestore } from "../../firebase/config";
import { ToolConfig } from "./ToolConfig";

const initalResults = [
  {
    index: 0,
    title: "Output",
    text: "",
    messages: "",
  },
];

const ToolComponents = ({ toolConfig }) => {
  const [messages, setMessages] = useState(null);
  const [messages1, setMessages1] = useState(null);
  const [optionMessage, setOptionMessage] = useState(null);
  const [optional, setOptional] = useState(null);
  let [resultsData, setResultsData] = useState(initalResults);
  const [colorButton, setColorButton] = useState("red");
  const [backgb, setBackgb] = useState("red");
  const [current, setCurrent] = useState(0);
  const {pathname} = useLocation()
  const [statusConversation, setStatusConversation] = useState(true);
  const [isShowNext, setIshowNext] = useState(false)
  const [searchParams] = useSearchParams();
  let [chatId, setChatId] = useState(searchParams.get("q"));
  let [inputsConfig, setInputsConfig] = useState(toolConfig?.inputs);
  const userEmail = getToken("email");

  const {
    checkLogin,
    dataInfo,
    setChangeContent,
    setStatusUp,
    statusQuery,
    statusRun,
    setStatusRun,
    setVal,
    setStatusQuery
  } = useContext(MainContext);

  const dataFilter = {
    totalToken: 0,
    plan: dataInfo?.plan,
    ...dataInfo?.userTrackings.find(
      (item) => item?.plan?.id === dataInfo?.plan.id
    ),
  };
  const shareQueryRef = useRef(null);
  


  const ROOT_CSS = css({
    height: "100%",
    width: "100%",
  });

  const param = {
    promptType: toolConfig.promptType,
    messages: [messages, messages1, optionMessage, optional],
  };

  const handleRun = async (index) => {
    if (!checkLogin() || !statusRun) {
      return
    }
    const resultParam = param?.messages.find((item)=>item !== null);
    if(resultParam){
      if (dataFilter?.totalToken < dataFilter?.plan?.tokensPerMonth || dataFilter?.plan?.name === "Pro") {
        let text = "";
        setCurrent(index);
        if (index === 0) {
          resultsData = initalResults
          setResultsData(resultsData)
          setIshowNext(false)
        } else {
          resultsData = [
            ...resultsData,
            {
              index,
              title: messages,
              text: "",
              messages: "",
            },
          ];
          setResultsData(resultsData);
          setChangeContent(resultsData);
        }
  
        try {
          setColorButton("bg_button");
          setBackgb("bg_button");
          setStatusRun(false);
  
          await axios.post(
            `${process.env.REACT_APP_HOST_API}/api/v1/gpt/stream`,
            param,
            {
              responseType: "stream",
              headers: { Authorization: `Bearer ${getAccessToken()}` },
              onDownloadProgress: (progressEvent) => {
                if (index === 0) {
                  updateMessage(index, {
                    messages: progressEvent.event.currentTarget.response,
                    title: "...",
                  });
                } else {
                  updateMessage(index, {
                    messages: progressEvent.event.currentTarget.response,
                  });
                }
                text = progressEvent.event.currentTarget.response;
              },
            }
          );
          let titleResponse = "";
          if (index === 0) {
            titleResponse = await axios.post(
              `${process.env.REACT_APP_HOST_API}/api/v1/gpt/chat`,
              { text },
              { headers: { Authorization: `Bearer ${getAccessToken()}` } }
            );
            updateMessage(index, {
              title: titleResponse.data?.text,
              messages: text,
              text,
            });
            setIshowNext(true)
          }
          await saveConversationFirebase({
            index,
            title: index === 0 ? titleResponse.data?.text : messages,
            messagesResponse: text,
            inputs: param.messages,
          });
          setBackgb("red");
          setColorButton("red");
          setStatusRun(true);
        } catch (error) {
          setColorButton("red");
          setBackgb("red");
          setStatusRun(true);
        }
      } else {
        alert('Exceeded maximum monthly token! Please upgrade to continue using codefy.ai.')
        setStatusUp(true);
      }

    }else{
      setColorButton("red");
      setBackgb("red");
      setStatusRun(true);
      let dataFilter;
      Object.keys(ToolConfig).map((item)=>{
    
        if(ToolConfig[item]?.url === pathname){
          dataFilter = ToolConfig[item];
          return dataFilter
        }
      })
      alert(`Missing input #1! (${dataFilter.inputs[0]?.title})`)
    }
  
  };

  const makeid = (length) => {
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;

    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  };

  const saveConversationFirebase = async ({
    index,
    title,
    messagesResponse,
    inputs,
  }) => {
    if (!chatId || index === 0) {
      chatId = makeid(7);
      setChatId(chatId);
      const url = new URL(window.location);
      url.searchParams.set("q", chatId);
      window.history.pushState(null, "", url.toString());
    }
    const message = {
      userEmail,
      chatId,
      text: messagesResponse,
      title,
      promptType: param.promptType,
      inputs: JSON.stringify(inputs),
      createdAt: serverTimestamp(),
    };
    await addDoc(collection(firestore, `messages`), message);
  };

  const updateMessage = async (index, props) => {
    resultsData = resultsData.map((item, _index) => {
      if (_index === index) {
        return { ...item, ...props };
      }
      return item;
    });
    setResultsData(resultsData);
    setChangeContent(resultsData);
  };

  const setNextConversationTitle = (messages) => {
    setMessages(messages);
  };

  const handleNextConversation = () => {
    setVal("")
    handleRun(resultsData.length);
  };

  useEffect(() => {
    async function getMessages() {
      const q = query(
        collection(firestore, `messages`),
        where("chatId", "==", chatId),
        orderBy("createdAt", "asc")
      );
      const docsSnap = await getDocs(q);
      const chatConversation = docsSnap?.docs[0]?.data();
      if (chatConversation) {
        inputsConfig = inputsConfig?.map((item, index) => ({
          ...item,
          defaultValue: JSON.parse(chatConversation.inputs)[index],
        }));
        resultsData = docsSnap?.docs.map((item) => ({
          title: item.data().title,
          messages: item.data().text,
        }));
        setInputsConfig(inputsConfig);
        setResultsData(resultsData);
        if (resultsData.length > 0) {
          setIshowNext(true)
        }
      }
    }
    getMessages();
    return () => {
      setStatusRun(true)
    }
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (shareQueryRef.current && !shareQueryRef.current.contains(event.target)) {
        setStatusQuery(false)
      }

    };

    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);
  

  return (
    <div className="relative">
      <div className="flex h-[calc(100vh-56px)] ">
        <div className=" max-w-[606px] 2xl:w-1/2 w-full bg-grayForm  ">
          <div className="p-4 min-h-[70px] border border-black border-r-0 bg-grayForm flex items-center justify-between  ">
            <h1 className="text-2xl h-9 text-text font-semibold font-main">
              Prompt
            </h1>
            <div onClick={() => handleRun(0)} style={{ pointerEvents: !statusRun ? 'none' : 'inherit' }}>
              <ButtonRun
                title="Run"
                icon={icon_play}
                backgb={colorButton}
                border="transparent"
                name={colorButton === 'red' ? 'white' : 'des'}
                statusRun={statusRun}
              />
            </div>
          </div>
          <div className="pt-6 px-9 pb-9 overflow-scroll max-h-[calc(100vh-56px-70px)] h-full">
            {inputsConfig.map((item) => {
              if (item.inputType === "code") {
                return (
                  <YourCode
                    messages={ item.defaultValue}
                    title={item.title}
                    des={item.des}
                    setMessages={setMessages}
                  />
                );
              } else if (item.inputType === "code1") {
                return (
                  <YourCode
                    messages={item.defaultValue}
                    title={item.title}
                    des={item.des}
                    setMessages={setMessages1}
                  />
                );
              } else if (!item.optional) {
                return (
                  <FormInfo
                    title={item.title}
                    des={item.des}
                    defaultValue={item.defaultValue}
                    place1={item.place1}
                    place2={item.place2}
                    place3={item.place3}
                    setMessages={setOptionMessage}
                  />
                );
              } else {
                return (
                  <Optional
                    title={item.title}
                    des={item.des}
                    defaultValue={item.defaultValue}
                    place1={item.place1}
                    place2={item.place2}
                    place3={item.place3}
                    setOptional={setOptional}
                  />
                );
              }
            })}
          </div>
        </div>
        <div className="flex flex-col  2xl:w-1/2 w-full bg-bg_hover  border-l border-black   relative">
          <div className="w-full">
            <div className={` ${resultsData ? "" : ""} min-h-[70px]  `}>
              {resultsData.map((item, i) => (
                <>

                  <Title
                    chatId={chatId}
                    index={i}
                    handleRun={handleRun}
                    title={item.title}
                    messages={item.messages}
                    color={colorButton}
                    statusRun={statusRun && i === current}
                  />
                </>
              ))}
            </div>

          </div>
          <div className="flex flex-col max-w-[1068px]  max-h-[calc(100vh-56px-70px)] h-full  bg-bg_hover    overflow-scroll  relative">
            <ScrollToBottom className={ROOT_CSS}>
              <div className={` ${resultsData ? "pb-[220px]" : ""} `}>
                {resultsData.map((item, i) => (
                  <>
                    <div  className={`${item.messages  ? 'overscroll-x-none ':'overflow-scroll'}  `}>
                      <ResponsiveGenerate   title={item.title} messages={item.messages} index={i} />
                    </div>
                  </>
                ))}
              </div>
            </ScrollToBottom>

            <div className="absolute bottom-0 right-0 left-0 z-10 max-w-[1068px]   ">
              {isShowNext > 0 && (
                <NextConversation
                  titleName="Continue the Conversation"
                  setTextConversation={(messages) =>
                    setNextConversationTitle(messages)
                  }
                  statusRun={statusRun}
                  handleRun={() => handleNextConversation()}
                  backgb={backgb}
                  statusConversation={statusConversation}
                  resultsData={resultsData}
                />
              )}
            </div>
            <div className="w-full h-[20%]  bg-gradient-to-b to-bg_hover  via-bg_hover from-transparent absolute bottom-0 right-0 left-0"></div>
          </div>
        </div>
      </div>



      {statusQuery ?
        <div
          className='bg-bg_black_blur  absolute -top-[56px] min-h-screen w-min-screen right-0 -left-[288px] flex items-center justify-center z-40'>
          <div ref={shareQueryRef} className='w-[534px] h-[240px] z-50'>
            <ShareQuery />

          </div>
        </div>
        :
        <></>}
    </div>
  );
};

export default ToolComponents;
