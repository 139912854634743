import { initializeApp, getApp, getApps } from "firebase/app";
import { getFirestore } from "firebase/firestore";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyAaTiJTKwyIUmwQ1TXIFbO8taQ3vf8OKC0",
    authDomain: "firestore-2f3e4.firebaseapp.com",
    projectId: "firestore-2f3e4",
    storageBucket: "firestore-2f3e4.appspot.com",
    messagingSenderId: "923466470026",
    appId: "1:923466470026:web:47097d753e0a469be15373",
    measurementId: "G-QXGEG7L45L"
};

// Initialize Firebase
const app = !getApps().length ? initializeApp(firebaseConfig) : getApp();
const firestore = getFirestore(app);

// export
export { firestore };
