import { useState, } from 'react';
import 'react-notifications/lib/notifications.css';
import { BrowserRouter, Routes, Route, } from 'react-router-dom';
import MainLayout from './layouts/MainLayout';
import Login from './pages/Login';
import Pricing from './pages/Pricing';
import Home from './pages/Home';
import MainLayOutLogin from './layouts/MainLayOutLogin';
import Dashboard from './pages/Dashboard';
import CodeWrite from './pages/Tools/CodeWrite';
import CodeExplainer from './pages/Tools/CodeExplainer';
import CodeChanger from './pages/Tools/CodeChanger';
import CodeDebugger from './pages/Tools/CodeDebugger';
import RegexGenerator from './pages/Tools/RegexGenerator';
import CodeTranslator from './pages/Tools/CodeTranslator';
import CodeOptimizer from './pages/Tools/CodeOptimizer';
import CodeComparer from './pages/Tools/CodeComparer';
import TopicExplainer from './pages/Tools/TopicExplainer';
import CodeLinter from './pages/Tools/CodeLinter';
import CodeSuggester from './pages/Tools/CodeSuggester';
import SyntaxChecker from './pages/Tools/SyntaxChecker';
import CodeLearner from './pages/Tools/CodeLearner';
import CodeRefactoring from './pages/Tools/CodeRefactoring';
import VariableRenamer from './pages/Tools/VariableRenamer';
import DatabaseQuery from './pages/Tools/DatabaseQuery';
import CodeCommenter from './pages/Tools/CodeCommenter';
import CodeDocumenter from './pages/Tools/CodeDocumenter';
import TestGenerator from './pages/Tools/TestGenerator';
import JsonGenerator from './pages/Tools/JsonGenerator';
import VertyFile from './pages/VertyFile';
import './App.css';
import 'react-toastify/dist/ReactToastify.css';
import MainPage from './pages/MainPage';
import RedirectQuery from './pages/RedirectQuery';



function App() {



  return (
    <div className={`m-0 scroll-smooth `}>

      <BrowserRouter>
        <Routes>
          <Route  element={<MainLayOutLogin />}>
            <Route path='/pricing' element={<Pricing />} />
            <Route path='/login' element={<Login />} />
            <Route path='/verify-login' element={<VertyFile />} />
            <Route path='/q/:chatId' element={<RedirectQuery />} />
          </Route>
          <Route path='/' >
            <Route index element={<MainPage />} />
          </Route>
          <Route path='/tools' element={<MainLayout />}>
            <Route path='code-writer' element={<CodeWrite />} />
            <Route path='code-explainer' element={<CodeExplainer />} />
            <Route path='code-changer' element={<CodeChanger />} />
            <Route path='code-debugger' element={<CodeDebugger />} />
            <Route path='regex-generator' element={<RegexGenerator />} />
            <Route path='code-translator' element={<CodeTranslator />} />
            <Route path='code-optimizer' element={<CodeOptimizer />} />
            <Route path='code-comparer' element={<CodeComparer />} />
            <Route path='topic-explainer' element={<TopicExplainer />} />
            <Route path='code-linter' element={< CodeLinter />} />
            <Route path='code-suggester' element={<CodeSuggester />} />
            <Route path='syntax-checker' element={< SyntaxChecker />} />
            <Route path='code-learner' element={<CodeLearner />} />
            <Route path='code-refactoring' element={< CodeRefactoring />} />
            <Route path='variable-renamer' element={< VariableRenamer />} />
            <Route path='database-query' element={< DatabaseQuery />} />
            <Route path='code-commenter' element={<CodeCommenter />} />
            <Route path='code-documenter' element={< CodeDocumenter />} />
            <Route path='test-generator' element={<TestGenerator />} />
            <Route path='json-generator' element={< JsonGenerator />} />
          </Route>
        </Routes>
      </BrowserRouter>

    </div>
  );
}

export default App;
