import tools from '../assets/images/tools.svg';
import promping from '../assets/images/prompting.svg';


export const DataHome = [
    {
        title: 'A diverse collection of developer tools.',
        des: 'New tools come out frequently, enhancing your developer workflow each week.',
        image: tools
    },
    {
        title: 'Get the most out of generative AI. We handle the prompt engineering.',
        des: 'Enter your problem into a beautiful, pre-programmed form. Our tools are engineered to get the best results without you needing to type a long and repetitive prompt.',
        image: promping
    },
]

