import React from 'react';
import { ButtonQuery, } from './ButtonRed';
import { icon_upload } from '../mocks/dataIcon';


const Title = (props) => {
    const { title, index, chatId } = props;
    
    return (
        <>
            {
                index === 0 ? <div className='text-white flex min-h-[68px]'>
                    <div className='w-[100%] p-4  border border-black flex items-center justify-between bg-bg_hover'>
                        <h1 className='text-2xl text-text font-semibold font-main'>{ title}</h1>
                        <ButtonQuery
                            chatId={chatId}
                            resultsData={title}
                            title={title}
                            titleButton="Share Query"
                            icon={icon_upload}
                            backgb='bg_button'
                            border='grayText'
                            name='grayButton'
                            borderWidth='border' />
                    </div>
                </div>
                    :
                    <></>

            }
        </>
    )
}

export default Title