import React, { useState, useContext } from 'react';
import { LoginSocialGoogle } from 'reactjs-social-login';
import { ButtonLogin, ButtonGoogle } from '../components/ButtonRed';
import { useNavigate, useLocation } from 'react-router-dom';
import { icon_close } from '../mocks/dataIcon';
import { loginEmail } from '../services/AuthService'
import { loginGoogle } from '../services/AuthService';
import { ToastContainer, toast } from 'react-toastify';
import { setUserInfo, setAccessToken, setRefreshToken, setEmailInfo, setNameLogin, getAccessToken } from '../services/helper/TokenHelper';
import MainContext from '../pages/MainContext';
import { useEffect } from 'react';


const LogginForm = (props) => {
    const [email, setEmail] = useState('');
    const [disabled, setDisabled] = useState(true)
    const [spin, setSpin] = useState(false)
    const navigate = useNavigate();
    const { getDataInfo, handleClickClose, setDataLogin, setFormLogin, id } = useContext(MainContext)
    const {pathname} = useLocation()

    const handleLoginGoogle = async (provider, data) => {
        if (data) {
            setUserInfo(data.picture)
            setEmailInfo(data.email)
            setNameLogin(data.name)

            const res = await loginGoogle({ "idToken": data.access_token, "avatar": data.picture })
            if (res) {
                setDataLogin(res.data)
                setAccessToken(res.data.token);
                setRefreshToken(res.data.refreshToken)
                getDataInfo();
                navigate('/')
            }
        }
        setFormLogin(false)
    }

    function isValidEmail(email) {
        return /\S+@\S+\.\S+/.test(email);
    }

    const handleChange = (event) => {

        if (!isValidEmail(event.target.value)) {

            setDisabled(true)
        } else {
            setDisabled(false)
        }
        setEmail(event.target.value.trim());
    }

    const handleLogin = async () => {
        setSpin(true)
        try {
            const res = await loginEmail({ email: email })
            if (res) {
                alert(`Link sent to ${email}`)
            }
            setSpin(false)

        } catch (error) {
            toast.error('Email not empty', {
                position: toast.POSITION.TOP_CENTER
            });
            setSpin(false)
        }

    }
    //  enter
    const handleChangeEnter = (e) => {

        if (e.keyCode === 13) {
            handleLogin();
            return
        }
        return;

    }

    useEffect(() => {
        const checkLoginInterVal = setInterval(() => {
            if (getAccessToken()) {
                window.location.href = '/'
            }
        }, 1000)
        return () => {
            clearInterval(checkLoginInterVal)
        }
    })

    return (
        <div id={id} className='w-[520px] min-w-[296px] sm:w-full   bg-grayForm rounded-md border border-colorBorder p-12 xs:p-6 relative' >
            <div className='flex justify-end absolute right-4 top-4 rounded-sm' onClick={handleClickClose}>
                <div className='hover:bg-bg_close duration-300 p-1 rounded-sm'>
                    {pathname === '/login' ? '' :icon_close}
                </div>

            </div>

            <h1 className='text-[32px] text-text font-bold mb-12 font-main'>Sign In / Register</h1>


            <div className=' flex flex-col'>
                <label htmlFor="" for="email" className='text-text mb-2 font-main'>Email
                </label>
                <input type="email" id='email_input' placeholder='Enter your email'
                    value={email}
                    title="Your Email"
                    required
                    onChange={handleChange}
                    onKeyDown={(e) => handleChangeEnter(e)}
                    className='p-3 rounded-sm bg-grayForm focus:border-red outline-none border border-grayText placeholder:text-grayButton text-white' />
                <div className='mt-4' >
                    <ButtonLogin handleLogin={handleLogin} />
                </div>
            </div>
            <p className='my-6 text-grayText font-main font-semibold text-center'>OR</p>

            <div className='hover:text-grayText hover:opacity-80 duration-100 ease-in'>
                <LoginSocialGoogle
                    client_id="647336222722-i9gqrm4jokk1uprv0gjjvrvg1dldinh3.apps.googleusercontent.com"
                    scope="openid profile email"
                    discoveryDocs="claims_supported"
                    access_type="offline"
                    onResolve={({ provider, data }) => {
                        handleLoginGoogle(provider, data)
                    }}
                    onReject={(err) => {
                        console.log(err);
                    }}
                >
                    <ButtonGoogle />
                </LoginSocialGoogle>
            </div>
            <ToastContainer />
        </div>
    )
}

export default LogginForm