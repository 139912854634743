import React, { useContext } from 'react'
import { dataUpgrade } from '../mocks/dataUpgrade';
import { icon_check, icon_close } from '../mocks/dataIcon';

import { ButtonUp } from './ButtonRed';
import MainContext from '../pages/MainContext';

const UpgradePlan = (props) => {

    const { setPayment, setIndexPayment, handleClickUp, dataInfo } = useContext(MainContext)

    const handleClickPlan = async (item) => {
        setPayment(true)
        setIndexPayment(item?.id)
    }

    const totalPlan = dataUpgrade.filter(item => item.id > dataInfo?.plan?.id).length

    return (
        <>
            {totalPlan > 1 ?
                <div className='text-white relative bg-bg_hover border overflow-hidden border-colorBorder rounded-md h-[370px] max-w-[768px]'>
                    <div className='flex items-center justify-between border-b border-colorBorder px-4 h-[56px]'>
                        <h1 className=' text-text text-lg font-semibold font-main h-[56px] leading-[56px] '>Plans</h1>
                        <span className='p-2 cursor-pointer hover:bg-[#2A2827] duration-300 ease-linear rounded-sm'
                            onClick={handleClickUp}>{icon_close}
                        </span>
                    </div>
                    <div className='flex h-[314px] bg-grayForm list-disc [&>*:first-child]:border-r [&>*:first-child]:border-colorBorder'>
                        {dataUpgrade.filter(item => item.id > dataInfo?.plan?.id).map((item, index) => {
                            return (
                                <div key={index} className='px-6 pt-6 w-1/2 '>
                                    <div className='flex items-center h-6'>
                                        <h1 className='text-lg text-text font-main font-semibold'>{item.header}</h1>
                                        <span className='ml-2 text-red text-2xl'>{item.icon}</span>
                                    </div>
                                    <p className='mb-6'>{item.text}</p>
                                    <div className='flex flex-col gap-2'>
                                        <div className='flex items-center h-4'>
                                            <span className='mr-2'>{icon_check}</span>
                                            <span className='text-des'>{item.line1}</span>
                                        </div>
                                        <div className='flex items-center h-4'>
                                            <span className='mr-2'>{icon_check}</span>
                                            <span>{item.line2}</span>
                                        </div>
                                        <div className='flex items-center h-4'>
                                            <span className='mr-2'>{icon_check}</span>
                                            <span>{item.line3}</span>
                                        </div>
                                        <div className='flex items-center h-4'>
                                            <span className='mr-2'>{icon_check}</span>
                                            <span>{item.line4}</span>
                                        </div>

                                    </div>
                                    <div className='mt-8 mb-6 w-full' onClick={() => handleClickPlan(item)}>
                                        <ButtonUp title='Upgrade Plan' />
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div> : <div className='text-white relative bg-bg_hover border overflow-hidden border-colorBorder rounded-md h-[370px] w-[484px]'>
                    <div className='flex items-center justify-between border-b border-colorBorder px-4 h-[56px]'>
                        <h1 className=' text-text text-lg font-semibold font-main h-[56px] leading-[56px] '>Plans</h1>
                        <span className='p-2 cursor-pointer hover:bg-[#2A2827] duration-300 ease-linear rounded-sm'
                            onClick={handleClickUp}>{icon_close}
                        </span>
                    </div>
                    <div className='h-[314.4px] bg-grayForm list-disc'>
                        {dataUpgrade.filter(item => item.id > dataInfo?.plan?.id).map((item, index) => {
                            return (
                                <div key={index} className='px-6 pt-6'>
                                    <div className='flex items-center h-6'>
                                        <h1 className='text-lg text-text font-main font-semibold'>{item.header}</h1>
                                        <span className='ml-2 text-red text-2xl '>{item.icon}</span>
                                    </div>
                                    <p className='mb-6'>{item.text}</p>
                                    <div className='flex flex-col gap-2'>
                                        <div className='flex items-center h-4 '>
                                            <span className='mr-2 text-des'>{icon_check}</span>
                                            <span className='text-des'>{item.line1}</span>
                                        </div>
                                        <div className='flex items-center h-4 '>
                                            <span className='mr-2 text-des'>{icon_check}</span>
                                            <span>{item.line2}</span>
                                        </div>
                                        <div className='flex items-center h-4 '>
                                            <span className='mr-2 text-des'>{icon_check}</span>
                                            <span>{item.line3}</span>
                                        </div>
                                        <div className='flex items-center h-4 '>
                                            <span className='mr-2 text-des'>{icon_check}</span>
                                            <span>{item.line4}</span>
                                        </div>

                                    </div>
                                    <div className='mt-8 mb-6 w-full' onClick={() => handleClickPlan(item)}>
                                        <ButtonUp title='Upgrade Plan' />
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            }
        </>
    )
}

export default UpgradePlan