import React, { useEffect, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getVerify } from '../services/AuthService';
import { getAccessToken, setAccessToken, setRefreshToken } from '../services/helper/TokenHelper.js'
import { ToastContainer, toast } from 'react-toastify';
import MainContext from './MainContext';

const VertyFile = () => {
    const { search } = useLocation();
    const navigate = useNavigate();
    const { getDataInfo } = useContext(MainContext)

    const getLoginToEmail = async () => {
        try {
            const res = await getVerify(search?.split('=')[1])
            setAccessToken(res.data?.token);
            setRefreshToken(res.data?.refreshToken)
            getDataInfo()
            navigate('/')
        } catch (error) {
            toast.error('Token expires...', {
                position: toast.POSITION.TOP_CENTER
            });
            setTimeout(() => { navigate('/login') }, 1000)
        }
    }

    useEffect(() => {
        getLoginToEmail()
    }, [])

    return (
        <div className='w-full h-screen flex justify-center items-center'>
            {/* <div
                className=" text-text inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite] mr-5"
                role="status">
                <span
                    className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                >Loading...</span>
            </div>

            </div> */}
            <ToastContainer />
        </div>
    )
}

export default VertyFile